import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./pages/Home/Index";
import AboutUs from "./pages/AboutUs/AboutUs";
import HowToPlayPage from "./pages/HowToPlay/HowToPlayPage";
import FantasyCricket from "./pages/FantasyCricket/FantasyCricket";
import Legality from "./pages/Legality/Legality";
import TermsOfServices from "./pages/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Faq from "./pages/Faq/Faq";
import PointSystem from "./pages/PointSystem/PointSystem";
import FairAndPlay from "./pages/FairAndPlay/FairAndPlay";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import TheLayout from "./common/TheLayout";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/App.scss";

function App() {
  return (
    <>
      <Router>
      <ScrollToTop />
          <Routes>
            <Route element={<TheLayout />} path="/"><Route index element={<Index />} exact /></Route>
            <Route element={<TheLayout />} path="/games/fantasy-cricket/how-to-play"><Route index element={<HowToPlayPage />} exact /></Route>
            <Route element={<TheLayout />} path="/fantasy-cricket"><Route index element={<FantasyCricket />} exact /></Route>
            <Route element={<TheLayout />} path="/legality"><Route index element={<Legality />} exact /></Route>
            <Route element={<TheLayout />} path="/terms-and-conditions"><Route index element={<TermsOfServices />} exact /></Route>
            <Route element={<TheLayout />} path="/privacy-policy"><Route index element={<PrivacyPolicy />} exact /></Route>
            <Route element={<TheLayout />} path="/faq"><Route index element={<Faq />} exact /></Route>
            <Route element={<TheLayout />} path="/games/fantasy-cricket/point-system"><Route index element={<PointSystem />} exact /></Route>
            <Route element={<TheLayout />} path="/fair-and-play"><Route index element={<FairAndPlay />} exact /></Route>
            <Route element={<TheLayout />} path="/about-us"><Route index element={<AboutUs />} /></Route>
          </Routes>
      </Router>
    </>
  );
}

export default App;
