import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../AboutUs/style.scss";

export default function AboutUs({ breadcrumb = true }) {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="About Us"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="About Us"
        breadcrumb={breadcrumb}
      />
      <section className="about-page inner-page">
        <Container>
          <Row>
            <Col md={12}>
              <p>
                At TopCrix11, we believe in providing the pure experience of
                fantasy sports, and our motto is to let you have a fantasy
                cricket gameplay experience that you have never had before.
                Although multiple fantasy sports apps and platforms are
                available, some things still need to be unlocked. It inspired us
                to launch the app for fantasy sports lovers like you.
              </p>
              <p>
                Our vision is to take the app to that level where new users can
                have a seamless experience of fantasy sports gameplay since they
                put their first step. At TopCrix11, you can dive into any
                contest with your strong team, play the match with your skills,
                and win exciting prizes followed by effortless withdrawal.
              </p>
              <p>
                For more details or queries, you can reach us at{" "}
                <a href="mailto:support@topcrix11.com">support@TopCrix11.com</a>
              </p>
              <h2 className="title">Disclaimer</h2>
              <p>
                TopCrix11- Fantasy cricket is an app based on the rules defined
                by AIGF(Est. 2016)- the apex industry body for online
                skill-based gaming. However, unless specified otherwise, it does
                not claim any association or affiliation with it or any sports
                governing body.
              </p>
              <p>
                Fantasy gaming has an exception under PGA-1867(public gaming
                act), based purely on gaming skills and knowledge. As it
                involves financial risks, the users are suggested to participate
                in their responsibility.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
