import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'

export default function FairAndPlay({ breadcrumb = true }) {
  return (
   <>
    <InnerBanner InnerBannerTitle="Fair And Play" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="Fair And Play"  breadcrumb={breadcrumb}/>
    <section className="howtoplay-page inner-page">
    <Container>
        <Row>
          <Col md={12}>
              <h2 className="title">Match Data</h2>
              <p>After closing a contest(s), Your11 uploads a PDF comprising the list of users with their Team names participating in a Contest(s) organized by Your11 accessible to all users to ensure transparency in the game.</p>
              <h2 className="title">Equal Opportunity</h2>
              <p>The users participating in a contest(s) organized by Your11 have equal opportunities wherein all the users are provided an equal virtual budget with same limits and are bound to abide by the same Terms & Conditions.</p>
              <h2 className="title">Verified Users</h2>
              <p>Your11 considers genuine and verified users to be a core requirement. To ensure Fair play on our platform we have framed guidelines which are mandatory for all users to follow. Moreover, we verify details like PAN and Bank Details before allowing users to withdraw funds or processing refunds.</p>
              <h2 className="title">Payment Security</h2>
              <p>A user creating more than one account on MyTeam11 platform will be considered as violating the Fair Play Terms of MyTeam11 as MyTeam11 neither allows nor encourages any user to create more than one user account on MyTeam11.</p>
              <p>Withdrawing your winnings by using any unlawful or illegal means or trying to withdraw winnings by illegal manners is considered as Fair Play violation at MyTeam11.</p>
              <h2 className="title">Skill element in fantasy sport game ?</h2>
              <p>Fantasy sports games are games which involve users drafting fantasy teams based on certain conditions from a list of players scheduled to play live games on a given day. Based on the performance of the players selected by the user to draft his/her team, the user collects points. The users are ranked based on the points their selected players accumulate throughout the contest as per their on-field actions and scoring metrics for the contests. Indian court in Shri Varun Gumber Vs. Union Territory of Chandigarh and others ruled Playing fantasy sports game by any user involves making a virtual team by him which would certainly require a considerable skill, judgment and discretion and held that fantasy games do not come under definition of gambling.</p>
              <h2 className="title">Legality of online fantasy sport business in india</h2>
              <p>The Supreme court have given a categorical finding that competition which involves substantial skill are not gambling activities. Such competition and business activities the protection of which is guaranteed by Article. 19 (1) of the constitution in State of Bombay vs. RMD Chamarbaugwala. In Varun Gumber case, Punjab and Harayana High court brought legality to the fantasy sports in India and held that fantasy sports is a legitimate business under Article 19 (1) (g) of the Constitution.</p>
              <p>Howbeit, Go Gaming doesn't allow users to play from the prohibited States i.e, Assam, Sikkim, Nagaland, Odisha and Telangana. Go Gaming takes great care to comply with all forthcoming changes in central and state legislation in India to ensure that our users are fully protected. Every user on our platform is carefully designed to comply with applicable statutes and regulations in India.</p>
              <h3 className="title">Referential Precedents</h3>
              <p>**State of Andhra Pradesh vs. K. Satyanarayana and others , AIR 1968 SC 825</p>
              <p>***Dr. K.R. Lakshmanan vs. State of Tamilnadu, AIR 1996 SC 1153.</p>
              <p>***D. Krishna Kumar and another vs. State of A.P 2002 (5) ALT 806</p>
              <p>**State of Bombay vs. RMD Chamarbaugwala AIR 1957 SC 699</p>
              <p>**Dominance Games Pvt Ltd vs. State of Gujarat 2018 (1) RCR(Cri) 629</p>
              <p>***Shri Varun Gumber vs. Union Territory of Chandigarh and others Gurudeep Singh Sachar vs. Union of India.</p>
          </Col>
        </Row>
      </Container>
    </section>
   </>
  )
}
