import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import '../Faq/style.scss'

export default function Faq({ breadcrumb = true }) {
  return (
    <>
    <InnerBanner InnerBannerTitle="Frequently Asked Questions" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="Faq" breadcrumb={breadcrumb} />
    <section className="faq-page inner-page">
        <Container>
            <Row>
                <Col md={12}>
        <Accordion defaultActiveKey="0">
            <Accordion.Item className='single-faq' eventKey="0">
                <Accordion.Header>What is Fantasy Cricket?</Accordion.Header>
                <Accordion.Body>
                It is a type of skill-based online gaming where you need to create a virtual team based on the performance of real players. Here you will earn points on the basis of the player's performance in the real match. 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='single-faq' eventKey="1">
                <Accordion.Header>How long does it take to withdraw the prize money from TopCrix11?</Accordion.Header>
                <Accordion.Body>
                The prize money withdrawal on TopCrix11 is seamless. Once you are declared as a winner as per your score in the match, you can immediately withdraw it directly to your bank account safely.  
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='single-faq' eventKey="2">
                <Accordion.Header>Are the free practice games available on TopCrix11?</Accordion.Header>
                <Accordion.Body>
                The practice games are available for new users who have put their first step into the world of fantasy cricket. It is the best way to get an insight into real fantasy gaming. It will let you know how actual things take place during a real-time fantasy cricket match on the app.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='single-faq' eventKey="3">
                <Accordion.Header>Are fantasy sports legal in India?</Accordion.Header>
                <Accordion.Body>
                In India, fantasy sports come under Article-19(1), which allows Indian citizens to participate in skilled-based online gaming. So, fantasy sports such as cricket, football, basketball or others are legal as per the constitution of India. 
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className='single-faq' eventKey="4">
                <Accordion.Header>How does a fantasy cricket league work?</Accordion.Header>
                <Accordion.Body>
                Working in a fantasy cricket league or any fantasy sports league is easy. To play a league first, you need to create a virtual team based on the performance metrics of a real player. Next, you need to compete with other users on the leaderboard. If your score is high compared to the others, you will be declared the winner.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}
