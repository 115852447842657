import React from 'react'
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../OurPartners/OurPartners.scss"
import MasterCard from '../../assets/images/mastercard.png';
import NetBanking from '../../assets/images/netbanking.png';
import Paytm from '../../assets/images/paytm.png';
import PhonePe from '../../assets/images/phonepe.png';
import Upi from '../../assets/images/upi.png';
import VisaCard from '../../assets/images/visacard.png';

export default function OurPartners() {
    var OurPartnersSlider = {
        dots: false,
        infinite: true,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            }
          ]
      };
  return (
    <section className='partners-section d-none'>
        <Container>
            <Slider {...OurPartnersSlider}>
                <div className='partner-logo'>
                    <img src={MasterCard} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={NetBanking} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Paytm} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={PhonePe} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={Upi} alt="" />
                </div>
                <div className='partner-logo'>
                    <img src={VisaCard} alt="" />
                </div>
            </Slider>
        </Container>
    </section>
  )
}
