import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'

export default function Legality({ breadcrumb = true }) {
  return (
   <>
    <InnerBanner InnerBannerTitle="Legality" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="Legality" breadcrumb={breadcrumb} />
    <section className="howtoplay-page inner-page">
    <Container>
        <Row>
          <Col md={12}>
              <p>Across different jurisdictions in the world, gaming or betting can be pondered upon games of skill and games of chance played for stakes are treated differently. Indian laws as well make a distinction between skill games and chance games. The anti-gambling laws of most Indian states (“Gaming Legislations”) exempt ‘games of mere skill’.</p>
              <h2 className="title">Constitution of india</h2>
              <p>Betting and Gambling is mentioned under the state List(Entries 34 and 62 of List II), under the Constitution of India, which essentially means the state has the authority to enact laws issues in relation to the subject of the betting and gambling.</p>
              <h2 className="title">Public gaming act, 1867</h2>
              <p>The Public Gaming Act, 1867 is a central legislation that largely covers the subject of gaming and betting. Section.12 of the Act exempts the “games of skill”.</p>
              <h2 className="title">Game of skill</h2>
              <p>A “game of skill” is a game in which success depends principally upon the superior knowledge, training, attention, experience, adroitness, personal attributes and capabilities of the player. Indian courts in <u>State of Andhra Pradesh Vs. K.Satyanarayana and Dr.K.R.Lakshmanan Vs. State of TamilNadu</u> have emphasised the skill element basing on the ‘dominant factor test’, or ‘predominance test’ which requires assessment and determination of whether chance or skill “is the dominating factor in determining the result of the game.</p>
              <h2 className="title">Skill element in fantasy sport game ?</h2>
              <p>Fantasy sports games are games which involve users drafting fantasy teams based on certain conditions from a list of players scheduled to play live games on a given day. Based on the performance of the players selected by the user to draft his/her team, the user collects points. The users are ranked based on the points their selected players accumulate throughout the contest as per their on-field actions and scoring metrics for the contests. Indian court in Shri Varun Gumber Vs. Union Territory of Chandigarh and others ruled Playing fantasy sports game by any user involves making a virtual team by him which would certainly require a considerable skill, judgment and discretion and held that fantasy games do not come under definition of gambling.</p>
              <h2 className="title">Legality of online fantasy sport business in india</h2>
              <p>The Supreme court have given a categorical finding that competition which involves substantial skill are not gambling activities. Such competition and business activities the protection of which is guaranteed by Article. 19 (1) of the constitution in State of Bombay vs. RMD Chamarbaugwala. In Varun Gumber case, Punjab and Harayana High court brought legality to the fantasy sports in India and held that fantasy sports is a legitimate business under Article 19 (1) (g) of the Constitution.</p>
              <p>Howbeit, Go Gaming doesn't allow users to play from the prohibited States i.e, Assam, Sikkim, Nagaland, Odisha and Telangana. Go Gaming takes great care to comply with all forthcoming changes in central and state legislation in India to ensure that our users are fully protected. Every user on our platform is carefully designed to comply with applicable statutes and regulations in India.</p>
              <h3 className="title">Referential Precedents</h3>
              <p>**State of Andhra Pradesh vs. K. Satyanarayana and others , AIR 1968 SC 825</p>
              <p>***Dr. K.R. Lakshmanan vs. State of Tamilnadu, AIR 1996 SC 1153.</p>
              <p>***D. Krishna Kumar and another vs. State of A.P 2002 (5) ALT 806</p>
              <p>**State of Bombay vs. RMD Chamarbaugwala AIR 1957 SC 699</p>
              <p>**Dominance Games Pvt Ltd vs. State of Gujarat 2018 (1) RCR(Cri) 629</p>
              <p>***Shri Varun Gumber vs. Union Territory of Chandigarh and others Gurudeep Singh Sachar vs. Union of India.</p>
          </Col>
        </Row>
      </Container>
    </section>
   </>
  )
}
