import React from 'react'
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import FeatureImage1 from '../../assets/images/1_Set-Reminder.png';
import FeatureImage2 from '../../assets/images/2_Extensive-Contest.png';
import FeatureImage3 from '../../assets/images/3_Create-Team.png';
import FeatureImage4 from '../../assets/images/4_Switch-Team.png';
import FeatureImage5 from '../../assets/images/5_Prediction.png';
import FeatureImage6 from '../../assets/images/6_Reverse.png';
import './style.scss'

export default function AmazingFeatures() {
    var AmazingFeaturesSlider = {
        dots: true,
        infinite: true,
        arrows:false,
        autoplay: true,
        // centerMode: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [            
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };
  return (
    <>        
        <Container className='position-relative'>
            <Slider {...AmazingFeaturesSlider}>           
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Set Reminder</h4>
                        <img className="img-fluid" src={FeatureImage1} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>Line up all match announcements and get real-time alerts on the home screen.</p>
                    </div>
                  </div>
                </div>
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Extensive Contests</h4>
                        <img className="img-fluid" src={FeatureImage2} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>Choose a desired contest from a wide range and play to win exciting prizes.</p>
                    </div>
                  </div>
                </div>
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Create Team</h4>
                        <img className="img-fluid" src={FeatureImage3} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>Pick top-performing players and Create your team to play and win the match.</p>
                    </div>
                  </div>
                </div>
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Team Switch </h4>
                        <img className="img-fluid" src={FeatureImage4} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>Choose a team from the multiple team name and, on a tap, switch to it.</p>
                    </div>
                  </div>
                </div>
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Prediction</h4>
                        <img className="img-fluid" src={FeatureImage5} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>AI-based instant key prediction about the players based on their performance.</p>
                    </div>
                  </div>
                </div>
                <div className='amazing-feature-slide'>
                  <div className="single-amazing-feature">
                    <div className="amazing-feature-img">
                      <h4>Reverse</h4>
                        <img className="img-fluid" src={FeatureImage6} alt="" />
                    </div>
                    <div className="amazing-feature-content">
                      <p>Choose the least performing player in a team and earn fantasy score points.</p>
                    </div>
                  </div>
                </div>
            </Slider>
        </Container>
    </>
  )
}
