import React, {useEffect} from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { FaCloudDownloadAlt } from 'react-icons/fa'
// import HeroImg from '../../assets/images/hero-img.png';
// import AppleStore from '../../assets/images/app-store.svg';
// import GooglePlay from '../../assets/images/google-play.svg';
// import HeroShape from '../../assets/images/hero-shape.png';

export default function HeroCommonSlide({title, SubTitle, discription, appleIcon, GooglePlayIcon, AppleStoreUrl, GooglePlayUrl}) {   
  return (
    <section id="Home" className='w-100'>
    {/* <img src={HeroShape} /> */}
        <Container>
            <Row className='align-items-center justify-content-between'>            
                <Col lg={7} xl={7}>
                <div className="hero-content position-relative text-start">
                    <h3 className='fadeInUp'><span>*</span> {SubTitle}</h3>
                    <h1 className='fadeInRight'>{title}</h1>
                    <h4 className='fadeInUp delay-2s'>{discription}</h4>
                    <Button type='button' className='downloadbtn fadeInUp delay-3s'><span className='download-icon'><FaCloudDownloadAlt /> </span><span>Download</span></Button>                  

                    <div className="get-download-link">
                    <span>OR</span>
                    <p>Get app link on email</p>
                        <Form action=''>
                        <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
                            <Form.Control className='form-control border-0' type="text" placeholder="Enter you email address" />
                        </Form.Group>
                            <Button className='btn btn-primary' type="button" variant="outline-primary">
                                <span>Get Link</span>
                            </Button>
                        </Form>
                    </div>
                </div>
                </Col>
                <Col md={6}>
                    <div className="hero-img">

                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
