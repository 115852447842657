import React, {useState, useEffect} from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg'
import LogoDark from '../../assets/images/logo-dark.svg'
import '../Header/style.scss';

export default function Header() {
  const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 100);
   });
 }, []);
  return (
    <header className={scroll ? "my-navbar fixed" : "my-navbar"}>  

      {['lg'].map((expand) => (
        <Navbar collapseOnSelect key={expand} expand={expand}>
          <Container>
            <Navbar.Brand>
              <Link className="headerlogo" to="/">
                <img src={Logo} alt="TopCrix11" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Nav.Link href='#'>
                  <Link className="headerlogo" to="/">
                    <img src={LogoDark} alt="TopCrix11" />
                  </Link>
                </Nav.Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link className="p-0" href='#'><Link className="nav-link" to="/">Home</Link></Nav.Link>
                  <Nav.Link className="p-0" href='#'><Link className="nav-link" to="/about-us">About</Link></Nav.Link>
                  <Nav.Link className="p-0" href='#'><Link className="nav-link" to="/games/fantasy-cricket/how-to-play">How to play</Link></Nav.Link>
                  <Nav.Link className="p-0" href='#'><Link className="nav-link" to="/faq">Faq</Link></Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}  

     
      </header>  
  )
}
