import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import '../OurGreatResults/OurGreatResults.scss';
import DotsShape from '../../assets/images/dots-shape.svg';
import AppScreen from '../../assets/images/Get-the-app-now.png'

export default function OurGreatResults() {
  return (
    <>
        <Container className="position-relative">
            <Row className="justify-content-between align-items-center">
                <Col md={6} lg={5} className="mb-5 mb-md-0 position-relative">                    
                    <img className='img-fluid' src={AppScreen} />
                </Col>
                <Col md={6} lg={6}>              
                    <div className="main-title">
                        <h2>Get The App Now!</h2>
                        <p>You can enter your mobile number here to get download link for apk.</p>
                        <Button type='button'><span>Comming Soon</span></Button>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}
