import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
export default function PrivacyPolicy() {
return (
<>
<InnerBanner InnerBannerTitle="Privacy Policy" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="Privacy Policy" />
<section className="terma-of-services-page inner-page">
   <Container>
      <Row>
         <Col md={12}>
         <p>This document is dedicated to the privacy policy related to the Portal and app (TopCrix11) for fantasy sports gameplay. Here you can also find safety-related information from the User's perspective.</p>
         <p>TopCrix11 facilitates Users to experience fantasy cricket games, contests, leagues and matches through the web-portal <a href="http://topcrix11.designaim.in" target="_blank">TopCrix11</a> and mobile application(s) (collectively referred to as the TopCrix11 (or we or us or ours).</p>
         <p>Any person utilizing the Portal or App or any of its features, including participation in ongoing or upcoming contests, games, leagues or matches being conducted on the Portal and app, shall be bound by this Privacy Policy.</p>
         <p>We respect and are committed to users' privacy and their right to protect sharing of their private information. Keeping users' information safe is a high-priority task for us always.</p>
         <p>For basic information about TopCrix11, you are welcome to read the 'About Us' section. Almost all services are free( except participation in the leagues and onboarding the players using credits); you need to register on the app.</p>
         <p>Our Portal or app collects information such as (i) information supplied by Users and (ii) information automatically tracked during User's navigation on TopCrix11 for only informational purposes.</p>
         <p>We recommend that you read this privacy policy before interacting with the app and web portal. However, your information will be safe with us, and we do not promote any sharing, modification, or transferring of it to third-parties for any purpose.</p>
         <p>In case of any disagreement with this privacy policy, please do not use the Portal for any purpose. According to the policy, accessing any part of the Portal indicates unspoken and intentional acceptance of our privacy policy.</p>
         <p>You have the right to stop sharing your information with us or withdraw your consent to the information collection, request for temporary suspension or deletion in that event you may miss the full advantages of Portal (& app), its features, functionalities and offered services.</p>
         <p>We also reserve the right not to provide the services or to block the users found violating any terms as per our policy or as per the rules for fantasy gaming by governing bodies such as FIFS, aka. Federation of Indian Fantasy Sports.</p>
         <h2 className="title">Disclosure/Sharing:</h2>
         <p>TopCrix11 may also share provided information and data concerning usage of the services or participation in the Games or both with engaged third-party service providers by us. The purpose may be data analytics or other similar purposes such as storage, service quality improvement and helping TopCrix11 in-house team to serve you better.</p>
         <p>Whenever or wherever we use your personal information (User identification that is not otherwise publicly available), we ensure that you get an appropriate notification. You will also be given the opportunity to withhold or withdraw your consent for your use other than as listed above.</p>
         <p>Use of Portal is directly referred to the agreement and grants consent to the collection, use and storage of the information by TopCrix11. It reserves the right to share, disclose and transfer information collected hereunder with its own affiliates.</p>
         <p>In the event TopCrix11 sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed or transferred as part of the transaction. You hereby expressly grant consent and permission to TopCrix11 for disclosure and transfer of information to such third parties.</p>
         <p>TopCrix11 may share information as provided by you and data concerning usage of the Services and participation in the Game with its commercial partners for the purpose of facilitating User engagement, for marketing and promotional purposes and other related purposes.</p>
         <p>Further, TopCrix11 reserves the right to disclose personal information as obligated by law, in response to duly authorized legal process, governmental requests and as necessary to protect the rights and interests of TopCrix11.</p>
         <h2 className="title">Purpose and Usage:</h2>
         <p>Users are required to provide certain information to register themselves on the app/portal or to avail of the key services on the Portal :</p>
         <ul className='ul-disck'>
            <li>Username</li>
            <li>Password</li>
            <li>Email address</li>
            <li>Date of birth</li>
         </ul>
         <p>To provide you access to the services & features offered through the Portal, verify your identity, and secure your account details, you are giving us permission to capture & record information related to your device, operating system, network information, and location information. </p>
         <p>In some cases, you may also be required to furnish additional information such as  Permanent Account Number, identity number or others. We may also collect Sensitive Personal Information ("SPI") like physical, physiological and mental health conditions; medical records and history; biometric information; sexual orientation and financial information.</p>
         <p>For payment authenticity and verification, we may collect information (in encrypted form for user safety) like payment instrument/modes used, cardholder name, credit/debit card number with an expiration date, banking details, wallet details etc. This information will be available in front of you at the time of making a payment to ensure seamless completion of it.</p>
         <p>Any financial information that you choose to provide on the Portal while making payment for any services, TopCrix11 does not collect, share or process with any third party without your express consent or by any other means that can harm your financial safety and privacy.</p>
         <p>While providing the services, Users may invite other existing Users or other Users ("Invited Users") to participate in any of the services via furnishing the info such as email address or Facebook Username. Later we (TopCrix11) may use this information to contact the invited User to register with TopCrix11 (if they are not an existing user) and participate in the game concerning which the User invited such a person.</p>
         <p>The participation of the invited Users in any game is subject to the terms mentioned in this Privacy Policy and the <a href="http://topcrix11.designaim.in/terms-and-conditions" target="_blank">terms and conditions</a> for the use of the Portal. The User at this moment represents that the Invited Users have consented and agreed to such disclosure to and use of their email address and social media id, such as Facebook Username by TopCrix11.</p>
         <p>All required specific information will be utilized to provide other services and gaming services to the Users they wish to participate. The furnished information enables us to improve service quality and provide an improved User-friendly game experience.</p>
         <p>TopCrix11 may share such information with affiliates and third parties in specialized circumstances only to provide services requested by the User, comply with legal processes, prevent fraud or imminent harm, and ensure the security of our network and services.</p>
         <h2 className="title">Links:</h2>
         <p>TopCrix11 also includes links to other websites. Such websites have their privacy policies, which are beyond TopCrix11's control. Once the User leaves TopCrix11's servers (the User can tell where they are by checking the URL in the location bar on the User's browser), the use of any information provided by the user is governed by the operator(which the User is visiting) as per privacy policy.</p>
         <p>That policy may differ from TopCrix11's own. If you or any other User didn't find the privacy policy on any of these sites via a link from the site's homepage, contacting the site directly for more information would be better. TopCrix11 is not responsible for such websites' privacy practices or content.</p>
         <h2 className="title">Security Procedures:</h2>
         <p>All information collected on TopCrix11 Portal or app is securely stored within our controlled database. The database stored on servers remains secured with a firewall, password protection, and multi-authorization and is strictly limited based on a need-to-know basis.</p>
         <p>We cannot guarantee the security of our database, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the Internet. However, we use high-security measures, but no security system is impenetrable.</p>
         <p>Further, the posted information in the discussion areas may remain available to anyone with Internet access. By using the Portal, you understand and agree that your information may be used in or transferred to countries other than India.</p>
         <p>We may periodically review from time to time and change our privacy policy to incorporate such future changes as the Internet is an ever-evolving medium. TopCrix11 can apply appropriate changes(if found suitable) without any notice to you.</p>
         <p>Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of the new policy. Any changes to our privacy policy will be posted on this page so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.</p>
         <h2 className="title">Advertising:</h2>
         <p>When TopCrix11 presents information to its online advertisers, it usually remains in the form of aggregated statistics on traffic to various pages within our site. The motive is to help them understand our audience and confirm the value of advertising on the Portal. When you register with TopCrix11, we will contact you on a timely basis to recommend content that can benefit you.</p>
         <p>Several deceptive emails, websites, blogs etc., claiming to be from or associated with TopCrix11 may or are circulating on the Internet. Check their authenticity by cross-confirming our logo, photos, links, content or other information.</p>
         <p>For your own protection, we strongly recommend not responding to such emails or using these websites, blogs etc., requesting the User to provide login name, password etc. or inform the User that they have won a prize/ gift or provide a method to commit an illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. These are not legal and do not have any connection or association with TopCrix11.</p>
         <p>We may only use the provided information, such as your email address or phone number, to contact you about the feedback Services availed by you or to inform you of our updated Services, if any.</p>
         <h2 className="title">Use of Cookies:</h2>
         <p>To provide improved usability of the Portal to our Users, we use "cookies" or similar electronic tools to collect information by assigning each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified computer.</p>
         <p>Unless the User voluntarily identifies themselves (e.g., through registration), TopCrix11 cannot know who the User is, even if we assign a cookie to the User's computer. The only personal information a cookie can contain is information supplied by the User.</p>
         <p>A cookie cannot read data off the User's hard drive. In some cases, TopCrix11's advertisers may also assign their own cookies to the User's browser (if the User clicks on their ad banners), but it does not come under our control.</p>
         <p>TopCrix11's web servers automatically collect limited information, such as- the User's computer's connection to the Internet, including User's IP address, when the User visits the Portal. (User's IP address is a number that lets computers attached to the Internet know where to send data to the User- such as the web pages viewed by the User).</p>
         <p>TopCrix11 uses this information to deliver its web pages to Users upon request, to improve its Portal for User interests, capture & measure traffic within the Portal and let advertisers know the geographic locations from where TopCrix11's visitors come.</p>
         <h2 className="title">Conditions of Use:</h2>
         <p>TopCrix11 DOES NOT WARRANT THAT THIS PORTAL, ITS SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE. TopCrix11 WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL TopCrix11 BE LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT OVER AN AMOUNT OF INR 100.</p>
         <h2 className="title">Retention of Data:</h2>
         <p>Your personal information may be retained and may continue to be used until (i) the relevant purposes for the use of your information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.</p>
         <h2 className="title">User Account and Data Deletion:</h2>
         <p>For the deletion of user accounts and personal data, you need to send an email to <a href="mailto:support@topcrix11.com">support@TopCrix11.com.</a></p>
         <p>We will do our best to handle your request immediately and, in any event, within one month of the following:</p>
         <ul className='ul-disck'>
            <li>Our receipt of your written request; or</li>
            <li>Our receipt of any further information we may ask you to provide to enable us to comply with your request, whichever is later.</li>
         </ul>
         <p>As an alternative to account deletion, you can also write us at  <a href="mailto:support@topcrix11.com">support@TopCrix11.com.</a> in the following cases such as:</p>
         <ul className='ul-disck'>
            <li>(i) Account Suspension- after which you will not be able to play paid contests using a User account but can access other parts of the Portal; or</li>
            <li>(ii) Temporary Account Deactivation- you will no longer be able to log into the Portal. You can still request reactivation of your account with all your account data.</li>
         </ul>
         <p>If you permanently deactivate the account, you will lose access to your account, including data and associated personal information. Although, you will still have a chance to reactivate your account by writing to <a href="mailto:support@topcrix11.com">support@TopCrix11.com.</a> within 6 months of deactivation.</p>
         <p>When you request data deletion, we activate the process to ensure its safe and complete removal from our servers. We also protect users' information from accidental or malicious deletion. The deletion process may be delayed because we must also delete it from our backup servers. </p>
         <h2 className="title">Applicable Law and Jurisdiction:</h2>
         <p>By visiting this Portal, you agree that the laws of the Republic of India, without regard to its conflict of laws principles, govern this Privacy Policy, and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms and Conditions.</p>
         <h2 className="title">Updating Information:</h2>
         <p>You can notify us (TopCrix11) if you find any changes, updates or modifications to your information. You can review, update or modify your information and User preferences at any time by logging into your Profile page on the Portal.</p>
         <h2 className="title">Contact Us:</h2>
         <p>If you have any queries or questions or need any clarifications regarding this Policy or complaints, write us at <a href="mailto:support@topcrix11.com">support@TopCrix11.com.</a>  If you want to provide feedback or suggestions, write to us at the above address.</p>
         </Col>
      </Row>
   </Container>
</section>
</>
)
}