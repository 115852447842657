import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg'
import LegalSecure from '../../assets/images/legal-and-secure.png'
import CustomerSupport from '../../assets/images/customer-support.png'
import FairplayPolicies from '../../assets/images/fairplay-policies.png'
import Withdraw from '../../assets/images/withdraw.png'
import Offers from '../../assets/images/offers.png'
import '../Footer/style.scss';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import BottomToTop from '../../components/ScrollToTop/BottomToTop';

export default function Footer() {
  return (
    <>
    <footer className='footer-section'>
      <Container>
          <Row className='gy-5'>
            <Col md={3} sm={6}  className='order-md-2'>
              <div className='footer-logo'>
                <Link className='logo' to="">
                  <img src={Logo} />
                </Link>
                <p>TopCrix11 enables the users to earn money by using their Sports skills and analytical skills for playing fantasy sports.</p>
              </div>
            </Col>
            <Col md={3} sm={6}  className='order-md-3'>
              <div className='footer-menus'>
                <ul>
                  <li>
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/games/fantasy-cricket/how-to-play">How to play</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/games/fantasy-cricket/point-system">Points system</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/fantasy-cricket">Fantasy cricket league</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} sm={6}  className='order-md-4'>
            <div className='footer-menus'>
                <ul>                  
                <li>
                    <Link className="nav-link" to="/faq">Faq</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/terms-and-conditions">Terms & conditions</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/privacy-policy">Privacy policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} sm={6}  className='order-md-5'>
              <div className='address mb-5'>
                <h2>Contact Us</h2>
                <a href='mailto:support@topcrix11.com'>support@TopCrix11.com</a>
              </div>
              <div className='socialmedia'>
                <ul className='mb-5'>
                  <li>
                    <a className="media-link facebook" href="" target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a className="media-link instagram" href="" target="_blank">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a className="media-link twitter" href="" target="_blank">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a className="media-link linkedin" href="" target="_blank">
                      <FaLinkedinIn /> 
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            
            <Col md={12} className='order-md-1'>
              <ul className="legal-info-tags">
                <li>
                <span><img src={LegalSecure} />  </span>            
                  100% Legal & Secure
                </li>
                <li>
                <span><img src={CustomerSupport} />  </span>   
                  24x7 Customer Support
                </li>
                <li>
                <span><img src={FairplayPolicies} />  </span> 
                  Strict Fairplay Policies
                </li>
                <li>
                <span><img src={Withdraw} />  </span>
                  Easy Withdrawals
                </li>
                <li>
                <span><img src={Offers} />  </span>
                  Exciting Offers
                </li>
              </ul>
            </Col>
            <Col md={12} className='order-md-5'>
              <div className="copyright">
                Copyright &copy; 2023. All rights reserved.
              </div>
            </Col>
          </Row>
      </Container>
      <div className="disclaimer">
        <Container>
          <p><strong>Disclaimer-</strong> This game involves financial risk and may be habit-forming. Play responsibly at your own risk.</p>
          <p>The game is applicable for people above 18 only.</p>
          <BottomToTop />
        </Container>
      </div>
    </footer>
    </>
  )
}
