import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import '../PointSystem/style.scss'

export default function PointSystem({ breadcrumb = true }) {
  return (
    <>
         <InnerBanner InnerBannerTitle="Point System" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="Point System" breadcrumb={breadcrumb}/>
    <section className="point-system-page inner-page">
    <Container>
        <Row>
          <Col md={12}>
          <h2 className="title">FANTASY POINT SYSTEM</h2>
          <h4 className="title">Here’s how Your TopCrix11 Team earns fantasy points</h4>
          <div className='table-responsive'>
            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type of Points</th>
                    <th>T10</th>
                    <th>T20</th>
                    <th>ODI</th>
                    <th>Test</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Being a part of the Starting 11</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Every run scored</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Every Wicket taken (excluding run out)</td>
                    <td>25</td>
                    <td>25</td>
                    <td>25</td>
                    <td>16</td>
                </tr>
                <tr>
                    <td>Catch taken</td>
                    <td>8</td>
                    <td>8</td>
                    <td>8</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Caught & Bowled</td>
                    <td>4</td>
                    <td>4</td>
                    <td>4</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Stumping/Runout</td>
                    <td>12</td>
                    <td>12</td>
                    <td>12</td>
                    <td>12</td>
                </tr>
                <tr>
                    <td>Thrower/Catcher</td>
                    <td>4/2</td>
                    <td>4/2</td>
                    <td>4/2</td>
                    <td>4/2</td>
                </tr>
                <tr>
                    <td>Dismissal for a duck(only for batsmen/wicket keepers and All rounders)</td>
                    <td>-2</td>
                    <td>-2</td>
                    <td>-3</td>
                    <td>-4</td>
                </tr>
                </tbody>
                </Table>
            </div>
            <h4 className="title">Bonus Points:</h4>
            <div className='table-responsive'>
                <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type of Points</th>
                    <th>T10</th>
                    <th>T20</th>
                    <th>ODI</th>
                    <th>Test</th>
                    </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Every boundary hit</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Every six-hit</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>Half-Century (50 runs scored by a batsman in a single inning)</td>
                    <td>4</td>
                    <td>4</td>
                    <td>4</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>Century (100 runs scored by a batsman in a single inning)</td>
                    <td>NA</td>
                    <td>16</td>
                    <td>8</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>Maiden Over</td>
                    <td>16</td>
                    <td>12</td>
                    <td>4</td>
                    <td>NA</td>
                </tr>
                <tr>
                    <td>4 wickets</td>
                    <td>NA</td>
                    <td>8</td>
                    <td>4</td>
                    <td>4</td>
                </tr>
                <tr>
                    <td>5 wickets</td>
                    <td>NA</td>
                    <td>16</td>
                    <td>8</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>2 wickets</td>
                    <td>8</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                </tr>
                <tr>
                    <td>3 wickets</td>
                    <td>16</td>
                    <td>4</td>
                    <td>NA</td>
                    <td>NA</td>
                </tr>
                <tr>
                    <td>30 runs scored by a batsman in a single innings</td>
                    <td>8</td>
                    <td>4</td>
                    <td>NA</td>
                    <td>NA</td>
                </tr>
                </tbody>
                </Table>
            </div>
            <h4 className="title">Economy Rate:</h4>            
          <div className='table-responsive'>
            <Table bordered hover>
            <thead>
                <tr>
                <th>Type of Points</th>
                <th>T10</th>
                <th>T20</th>
                <th>ODI</th>
                <th>Test</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Minimum overs bowled by a player to be applicable</td>
                <td>1 over</td>
                <td>2 over</td>
                <td>5 over</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 5 and 8 runs per over</td>
                <td>NA</td>
                <td>1</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 4.99 and 4 runs per over</td>
                <td>NA</td>
                <td>2</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 4 runs per over</td>
                <td>NA</td>
                <td>3</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 9 and 10 runs per ove</td>
                <td>NA</td>
                <td>-1</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 10.01 and 11 runs per over</td>
                <td>NA</td>
                <td>-2</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Above 11 runs per over</td>
                <td>NA</td>
                <td>-3</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 4.5 and 3.5 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>1</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 3.49 and 2.5 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>2</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 2.5 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>3</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 7 and 8 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>-1</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 8.01 and 9 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>-2</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Above 9 runs per over</td>
                <td>NA</td>
                <td>NA</td>
                <td>-3</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 6 runs per over</td>
                <td>3</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 6 and 6.99 runs per over</td>
                <td>2</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 7 and 8 runs per over</td>
                <td>1</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 11 and 12 runs per over</td>
                <td>-1</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 12.01 and 13 runs per over</td>
                <td>-2</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Above 13 runs per over</td>
                <td>-3</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            </tbody>
            </Table>
            </div>
            <h4 className="title">Strike Rate <span>(except Bowlers):</span></h4>
            
          <div className='table-responsive'>
            <Table bordered hover>
            <thead>
                <tr>
                <th>Type of Points</th>
                <th>T10</th>
                <th>T20</th>
                <th>ODI</th>
                <th>Test</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Minimum balls faced by a player to be applicable</td>
                <td>5 Balls</td>
                <td>10 Balls</td>
                <td>20 Balls</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 60 and 70 runs per 100 balls</td>
                <td>NA</td>
                <td>-1</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 50 and 59.99 runs per 100 balls</td>
                <td>NA</td>
                <td>-2</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 50 runs per 100 balls</td>
                <td>NA</td>
                <td>-3</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 50 and 60 runs per 100 balls</td>
                <td>NA</td>
                <td>NA</td>
                <td>-1</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 40 and 49.99 runs per 100 balls</td>
                <td>NA</td>
                <td>NA</td>
                <td>-2</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 40 runs per 100 balls</td>
                <td>NA</td>
                <td>NA</td>
                <td>-3</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 90 and 100 runs per 100 balls</td>
                <td>-1</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Between 80 and 89.99 runs per 100 balls</td>
                <td>-2</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            <tr>
                <td>Below 80 runs per 100 balls</td>
                <td>-3</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
            </tr>
            </tbody>
            </Table>
            </div>
            <h4 className="title">Points to Remember :</h4>
            <p>Your team’s Captain receives 2 times the points. The Vice-Captain and Man of the Match receives 1.5 times the points.</p>
            <p>- In the case of run-outs involving 3 or more players from the fielding side, points will be awarded only to the last 2 players involved in the run-out.</p>
            <p>- Starting points are assigned to any player on the basis of the announcement of his/her inclusion in the team. However, in case the player is unable to start the match after being included in the team sheet, he/she will not score any points.</p>
            <p>- Any player scoring a century will only get points for the century and no points will be awarded for a half-century. Any score over a century will be eligible for bonus points only for the century.</p>
            <p>- No events occurring during a Super Over will be considered for points to be awarded to a player.</p>
            <p>- Substitutes on the field will not be awarded points for any contribution. However, 'Concussion Substitutes' will be awarded points awarded 2 points for making an appearance in a match and will be awarded points for any contribution as per the Fantasy Points System.</p>
            <p>- Teams will be allowed to replace players displaying symptoms of COVID-19 during a Test match. In line with concussion replacements. The regulation for COVID-19 replacements will not be applicable in ODIs and T20Is.</p>
            <p>- Data is provided by our service providers and points awarded live in-game are subject to change as long as the status is 'In progress' or 'Waiting for review'. Once the match is marked as 'Completed' i.e. winners are declared, no further adjustments to any points awarded to any player/ team will be made.</p>
            <p>For reverse format</p>
            <p>a. We are going to deduct 50% for Captain & 25% for Vice-Captain & Man of the match, the logic applies the same if it is negative too, for example, a player when marked as a captain gets total points for the match say -2 then his points tally will be -3.</p>
            <p>b. If the selected player is not in Playing 11 points will be added as below.</p>
            <ol className='ol-number'>
                <li>T10 – 50</li>
                <li>T20 – 50</li>
                <li>ODI – 75</li>
                <li>Test – 75</li>
            </ol>
            <p>- If the selected player is in the Playing 11 he will be given any points, whereas in the regular format he is given a bonus of 2 points.</p>
            <p>- For ODI and TEST if the selected player is not in playing 11, further if they are marked as Captain / Vice Captain their points are calculated as 75X2 points for Captain and 75X1.5 points for Vice-Captain.</p>
            <p>- Whereas in T10 and T20  if the selected player is not in playing 11, further if they are marked as Captain / Vice Captain their points are calculated as 50X2 points for Captain and 50X1.5 points for Vice-Captain.</p>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  )
}
