import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import './style.scss'
import FeatureImg from '../../assets/images/How-to-play.png'
import { Link } from 'react-router-dom'
export default function HowToPlay() {
  return (
    <>
    <section className="how-to-play-section">
        <Container>
            <div className="main-title text-center">
                <h2>How to play on TopCrix11?</h2>
                <p>We have very user-friendly app flow which allows you to choose your favorite content in one go.</p>
            </div>
            <Row>
                <Col lg={6} className="order-lg-2">
                    <div className="features-img">
                        <img className='img-fluid' src={FeatureImg} alt="FeatureImg" />
                    </div>
                </Col>
                <Col lg={6} className="mt-5 pt-5">                    
                    <Row className='gy-4'>
                        <Col md={12}>
                            <Card className='bg-transparent single-feature border-0'>
                                <div className="feature-number">1</div>
                                <div className="feature-content">
                                    <h4>Select a Match</h4>
                                    <p>Select an upcoming match of your choice</p>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className='bg-transparent single-feature border-0'>
                                <div className="feature-number">2</div>
                                <div className="feature-content">
                                    <h4>Create your TopCrix11 Team</h4>
                                    <p>Use your sports knowledge to create a fantasy team using 100 credits</p>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className='bg-transparent single-feature border-0'>
                                <div className="feature-number">3</div>
                                <div className="feature-content">
                                    <h4>Join Free & Cash Contests</h4>
                                    <p>Participate in cash or practice contests and go for glory!</p>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12} className="text-center">
                            <Link className='btn btn-primary btn-outline-primary' to='/games/fantasy-cricket/how-to-play'><span>Play Now</span></Link>
                        </Col>
                    </Row>                    
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}
