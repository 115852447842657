import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'
import '../HowToPlay/style.scss'

export default function HowToPlayPage({ breadcrumb = true }) {
  return (
    <>
    <InnerBanner InnerBannerTitle="How To Play" PreviousPageName="Home" PreviousPageLink="/" CurrentPage="How To Play" breadcrumb={breadcrumb} />
    <section className="howtoplay-page inner-page">
      <Container>
      <Row>
        <Col md={12} className="mb-5">
          <h2>T11 Regular</h2>
          <p>Create a team of handpicked & top-performing players and get set go for the match</p>
          <ul>
            <li>Select the match you want to play.</li>
            <li>Create your team of 11 players on both sides using 100 credits.</li>
            <li>Choose a captain and a vice-captain.</li>
            <li>Captain gets twice the points scored.</li>
            <li>The Vice-captain gets one and half times the points scored.</li>
            <li>Choose the contest from an available extensive range.</li>
            <li>Click 'Preview' for a final check and join the cash contest. </li>
            <li>Click on 'My Contest' to view the ranking and players' performance.</li>
          </ul>
        </Col>
        <Col md={12}>
          <h2>T11 Reverse</h2>
          <p>Identify the least-scoring players, create the team and aim for the minimum possible points to win the game</p>
          <ul>
            <li>Tap on the match you want to play.</li>
            <li>Select T11Reverse.</li>
            <li>Choose 11 players from both teams with 100 credits.</li>
            <li>Choose a captain and a vice-captain.</li>
            <li>Choose the contest from a wide range. </li>
            <li>Click on 'preview' for a final check and join the cash contest.</li>
            <li>Click on 'My Contest' to view the ranking and players' performance.</li>
          </ul>
        </Col>
      </Row>
      </Container>
    </section>
    </>
  )
}
