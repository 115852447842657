import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InnerBanner from '../../components/InnerBanner/InnerBanner'

export default function FantasyCricket({ breadcrumb = true }) {
  return (
    <>
    <InnerBanner InnerBannerTitle="Fantasy Cricket" PreviousPageName="Home" PreviousPageLink="" CurrentPage="Fantasy Cricket" breadcrumb={breadcrumb} />
    <section className="about-page inner-page">
        <Container>
            <Row>
                <Col md={12}>
                    <p>Fantasy cricket is a skilled game where a user can participate in a league or contest as on their experience, gameplay skills, analytical ability, and decision-making power.</p>
                    <p>As a user is responsible for making their own decisions, fantasy cricket does not fall under any restriction by the Indian Constitution. So the act of public gambling, 1867- India is not applicable as Section 12 of the central gambling act exempts it.</p>
                    <p>Still, it is banned in states such as Sikkim, Assam, Odisha, Telangana, Nagaland & Andhra Pradesh as per state law. So, TopCrix11 does not recommend and permit the users to download and install this app once identified from these states.</p>
                    <p>From the historical point of view- In 2006, during the IPL, fantasy cricket gaming was introduced. To date, other fantasy sports are also available such as football, basketball, hockey, badminton etc.</p>
                    <p>The TopCrix11 is a fantasy cricket app that supports fair play and does not support any activity or violation of rules against the fantasy gaming rules and regulations. It follows the guidelines provided by governing authorities such as <a href="https://fifs.in" target="_blank">FIFS</a>, India.</p>
                </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}
