import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import MainSlider from '../../components/MainSlider/HeroSection';
import OurPartners from '../../components/OurPartners/OurPartners';
import HeroImg from '../../assets/images/hero-img2.png';
import AmazingFeatures from '../../components/AmazingFeatures/AmazingFeatures';
import GetDoloadLink from '../../components/GetDowloadLink/GetDoloadLink';
import HowToPlay from '../../components/HowToPlay/HowToPlay';
import Testimonials from '../../components/Testimonials/Testimonials';
import '../Home/style.scss';
import OurGreatResults from '../../components/OurGreatResults/OurGreatResults';
import { Link } from 'react-router-dom';

export default function Index() {
  return (
      <>
        <MainSlider />
        <section className="about-section">
          <div className="fantasytitle">TopCrix11</div>
            <Container className='position-relative'>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="about-img">
                    <img className="img-fluid" src={HeroImg} alt="AboutImg" />
                    <div className="shape1"></div>
                    <div className="shape2"></div>
                  </div>
                </Col>
                <Col md={6}>
                    <div className="main-title">
                      <h2>About TopCrix11</h2>
                      <p>Welcome to TopCrix11, the ultimate destination for cricket fans who love to play fantasy cricket. Whether you are a seasoned fantasy cricket player or a beginner, we have something for everyone. Our app offers a range of leagues, tournaments, and challenges that cater to different levels of expertise and interests. We also offer a variety of payment options to make it convenient for our users to participate in various contests.</p>
                    </div>
                      <Link to="/about-us" className="mw-150 btn btn-primary"><span>Learn More</span></Link>
                </Col>
              </Row>
            </Container>
        </section>
        <OurPartners />      
          <HowToPlay />  

        <GetDoloadLink />
        <section className='amazing-feature-section'>        
          <Container className='position-relative'>
              <Col md={12}>
                <div className="main-title text-center">
                <h2>Amazing features</h2>
                  <p>We are offering some amazing features to our users. take a look at few of them here.</p>
                </div>
              </Col>
        </Container>
        <AmazingFeatures />
        </section>
        <section className='great-results-section p-0'>
            <OurGreatResults />
        </section>
          <Testimonials />
      </>
  )
}
