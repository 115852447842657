import React from 'react'
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import TestimonialAuthor1 from '../../assets/images/testimonial-author-1.png';
import '../Testimonials/style.scss';

export default function Testimonials() {

    var TestimonialsSlider = {
        dots: false,
        infinite: true,
        arrows:true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                }
              }
          ]
      };

  return (
    <section id='Feedbacks' className='testimonials-section'>
        <Container>
            <Row>
                <Col md={12}>
                    <div className="main-title">
                        <h2>Hear What TopCrix11 Fans Say!</h2>
                        <p>TopCrix11 is one stop destination to play and experience rich fantasy sports gaming. Here are some words by our winners that we love to share with you.</p>
                    </div>
                </Col>
                <Col md={12}>
                <Slider {...TestimonialsSlider}>
                    <div className='single-testimonial'>                        
                        <div className='testimonial-userinfo'>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                            <div className='client-name'>
                                <h3> Won ₹50 Lakhs</h3>
                                <p>Jonah D. Kwon</p>
                                <div className='feedback-starts'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                    </div>
                    <div className='single-testimonial'>                        
                        <div className='testimonial-userinfo'>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                            <div className='client-name'>
                                <h3> Won ₹23 Lakhs</h3>
                                <p>Jonah D. Kwon</p>
                                <div className='feedback-starts'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                    </div>
                    <div className='single-testimonial'>                        
                        <div className='testimonial-userinfo'>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                            <div className='client-name'>
                                <h3> Won ₹58 Lakhs</h3>
                                <p>Jonah D. Kwon</p>
                                <div className='feedback-starts'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                    </div>
                    <div className='single-testimonial'>                        
                        <div className='testimonial-userinfo'>
                            <div className='client-img'>
                                <img src={TestimonialAuthor1} alt="" />
                            </div>
                            <div className='client-name'>
                                <h3> Won ₹32 Lakhs</h3>
                                <p>Jonah D. Kwon</p>
                                <div className='feedback-starts'>
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-text'>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum cumsan lacus vel facilisis.’’</p>
                        </div>
                    </div>
                </Slider>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
