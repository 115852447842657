import React from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { FaRegEnvelope } from "react-icons/fa";
// import downloadAppImg from '../../assets/images/download-app.png';
import CtaBg from "../../assets/images/cta-bg-shape.png";
import DownloadImg from "../../assets/images/dowanload_app_middle.png";
import "../GetDowloadLink/style.scss";

export default function GetDoloadLink() {
  return (
    <>
      <section className="downloadlink-section">
        <Container>
          <div className="downloadlink-card">
            <img className="cta-img" src={CtaBg} />
            <Row className="align-items-center">
              <Col md={6} className="order-md-2">
                <div className="get-download-img">
                  {/* <img src={downloadAppImg} alt='' /> */}
                  <img src={DownloadImg} alt="" />
                </div>
              </Col>
              <Col md={6} className="order-md-1">
                <div className="downloadlink-form">
                  <h2>Download App</h2>
                  <p>
                    You can enter your email address here to get download link
                    for apk.
                  </p>
                  <Form action="">
                    <InputGroup className="mb-4">
                      <InputGroup.Text id="email">
                        <FaRegEnvelope />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Enter your email address"
                        aria-label="Enter your email address"
                        aria-describedby="email"
                      />
                    </InputGroup>
                    <Button
                      className="btn btn-primary"
                      type="button"
                      variant="outline-primary"
                    >
                      <span>Get App Link</span>
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
