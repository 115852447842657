import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { Link } from "react-router-dom";

export default function TermsOfServices({ breadcrumb = true }) {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="Terms and Condition"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="Terms and Condition"
        breadcrumb={breadcrumb}
      />
      <section className="terma-of-services-page inner-page">
        <Container>
          <Row>
            <Col md={12}>
              <h4 className="title">Terms and Conditions@TopCrix11
              <p>Last Updated: 15th July, 2023</p>
              </h4>
              
              <h2 className="title">1. TopCrix11</h2>
              <p>
                TopCrix11 as used herein shall be construed as a collective
                reference to TopCrix11.
              </p>
              <h2 className="title">2. Usage of TopCrix11:</h2>
              <ul>
              <li>
                Any person ("User") accessing TopCrix11 portal or the App
                ('TopCrix11 platform') for participating in ongoing or upcoming
                contests and games (including fantasy games), available on
                TopCrix11 platform ("Contest(s)") ('TopCrix11 Services') shall
                be bound or obligated or compelled by these Terms and
                Conditions, and all other rules, regulations and terms of use
                referred in this document mentioned or provided by TopCrix11 in
                relation to any TopCrix11 Services.
              </li>
              <li>
                TopCrix11 shall be authorized to modify these Terms and
                Conditions, rules, regulations and terms of use referred here or
                provided by TopCrix11 in relation to any of (TopCrix11)
                Services, at any time, by posting the same on Portal
                (TopCrix11). Use of TopCrix11 Portal or App considered as User's
                unspoken acceptance of such Terms and Conditions, rules,
                regulations and terms of use referred to herein or provided by
                TopCrix11 in relation to any TopCrix11 Services, as may be
                re-scripted from time to time. TopCrix11 may also notify the
                User of any change or modification in these Terms and
                Conditions, rules, regulations, and terms of use mentioned or
                provided by TopCrix11 by sending an email to the User's
                registered email address or posting notifications in the User
                accounts, at its sole and absolute discretion. The User may deny
                the options provided in such an email or notification to
                indicate their non-acceptance regarding modified Terms and
                Conditions, rules, regulations and terms of use referred under
                Privacy Policy by TopCrix11. If User(s) does not send their
                consent for objection to such options within the time frame
                prescribed in the email or notification, they will be deemed to
                have accepted the modified Terms and Conditions, rules,
                regulations and terms of use referred to herein or provided by
                TopCrix11.
              </li>
              <li>
                Certain TopCrix11 Services being provided on TopCrix11 Platform
                or Portal or App may be subject to additional rules and
                regulations set down in that respect. If these Terms and
                Conditions have any conflict with the additional conditions, the
                additional conditions will take supersede.
              </li>
              <li>
                <h4 className="mt-5">TopCrix11 may, at its exclusive discretion:</h4>
              <ul>
                <li>
                  Restrict, suspend, or terminate any User's access to all or
                  any part of TopCrix11 or TopCrix11 Platform or App Services;
                </li>
                <li>
                  Change, suspend, or discontinue all or any part of the
                  TopCrix11 Portal/App/Platform Services;
                </li>
                <li>
                  Reject, move, or remove any information/material that may be
                  submitted by a User;
                </li>
                <li>
                  Move/remove/modify any content that is available on TopCrix11
                  Platform;
                </li>
                <li>
                  Deactivate or delete a User's account and all account related
                  information;
                </li>
                <li>
                  Establish general practices and concerned limits use of
                  TopCrix11 Platform;
                </li>
                <li>
                  Offer discounts to its users in the form of "Cash Bonus" or
                  cash benefits. All such discounts shall be attributed in a
                  separate account called as Cash Bonus Account;
                </li>
                <li>
                  Revise or modification or alteration or amend and/or deletions
                  to the roster of players available for selection in a
                  Contest/League/Match on account of revisions to the roster of
                  players involved in the relevant Sports Event
                  (Contests/Leagues/Matches/Tournaments);
                </li>
                <li>
                  Assign its rights and liabilities to all User accounts
                  hereunder to any entity (post such assignment intimation of
                  such assignment shall be sent to all Users to their registered
                  email ids).
                </li>
                </ul>                
              </li>
              <li>
                If a User breaches or violates the policy points, or TopCrix11
                reasonably believes that such User has breached these Terms and
                Conditions, or has illegally or improperly used TopCrix11 or the
                TopCrix11 Services, We (TopCrix11) may, at our exclusive
                discretion and without notice to the User, restrict, suspend, or
                terminate such User's access to all or any part of TopCrix11
                Contests or the TopCrix11 Platform, deactivate or delete the
                User's account and related information.
              </li>
              <li>
                If TopCrix11 charges its Users a platform fee in respect of any
                TopCrix11 Services, TopCrix11 shall, without delay, repay such
                platform fee in the event of suspension or removal of the User's
                account or TopCrix11 Services on account of any negligence or
                deficiency on the part of TopCrix11, but not if such suspension
                or removal is affected due to:
              <ol className="mt-4">
                <li>
                  any breach or inadequate performance by the User of any of
                  these Terms and Conditions; or
                </li>
                <li>
                  any circumstances beyond the reasonable control of TopCrix11.
                </li>
              </ol>
              </li>
              <li>
                Users consent to receiving communications such as announcements,
                administrative messages and advertisements from TopCrix11 or any
                of its partners, licensors or associates TopCrix11 may collect
                certain information such as name, address, location, email
                address, and contact number.
              </li>              
              </ul>

              <h2 className="title">3. Intellectual Property</h2>
              <ul>
              <li>
                TopCrix11 has authorization or reserves the right to include
                content created by TopCrix11, its collaborators, partners,
                affiliates, licensors, associates and/or Users. The intellectual
                property rights ("Intellectual Property Rights") in all software
                underlying TopCrix11 and the TopCrix11 Platform and material
                published on TopCrix11, including (but not limited to) games,
                contests, software, advertisements, written content,
                photographs, graphics, images, illustrations, marks, logos,
                audio or video clippings and Flash animation, is owned by
                TopCrix11, its partners, licensors and/or associates. Users are
                restricted to not modify, alter, publish, transmit,participate
                in the transfer or sale of, reproduce, create derivative works
                of, distribute, publicly perform, publicly display, or exploit
                the content available on TopCrix11 Platform by any means.{" "}
              </li>
              <li>
                Users may request permission to use any TopCrix11 content by
                writing in to TopCrix11 Support.
              </li>
              <li>
                Users are merely accountable for all materials (whether publicly
                posted or privately transmitted) that they upload, post, e-mail,
                transmit, or otherwise make available on TopCrix11 ("Users'
                Content'').Each User has to prove that he/she owns all
                Intellectual Property Rights in the User's Content and that no
                part of the User's Content infringes any third party rights.
                Users are not subjected to display or use of the names, logos,
                marks, labels, trademarks, copyrights or intellectual and
                proprietary rights of any third party on TopCrix11.Users agree
                to indemnify and hold harmless TopCrix11, its directors,
                employees, affiliates and assigns against all costs, damages,
                loss and harm including towards litigation costs and counsel
                fees, in respect of any third party claims that may be initiated
                including for infringement of Intellectual Property Rights
                arising out of such display or use of the names, logos, marks,
                labels, trademarks, copyrights or intellectual and proprietary
                rights on TopCrix11, by such User or through the User's
                commissions or omissions
              </li>
              <li>
                Users are requested to grant permission to TopCrix11 and its
                affiliates, partners, licensors and associates a worldwide,
                irrevocable, royalty-free, non-exclusive, sub-licensable licence
                to use, reproduce, create derivative works of, distribute,
                publicly perform, publicly display, transfer, transmit, and/or
                publish Users' Content for any of the following purposes:
              <ol>
                <li>displaying Users' Content on TopCrix11</li>
                <li>
                  distributing Users' Content, either electronically or via
                  other media, to other Users seeking to download or otherwise
                  acquire it, and/or
                </li>
                <li>
                  storing Users' Content in a remote database accessible by end
                  users, for a charge.
                </li>
                <li>
                  This license shall be regulated to the distribution and the
                  storage of Users' Content in any form, medium, or technology.
                </li>
              </ol>
              </li>
              <li>
                All names, logos, marks, labels, trademarks, copyrights or
                intellectual and proprietary rights on TopCrix11(s) belonging to
                any person (including User), entity or third party are
                recognized as proprietary to the respective owners and any
                claims, controversy or issues against these (names, logos,
                marks, labels, trademarks, copyrights or intellectual and
                proprietary rights) will be directly addressed to the respective
                parties under notice to TopCrix11.
              </li>
              </ul>

              <h2 className="title">
                4. Third Party Sites, Services and Products
              </h2>
              <ul>
              <li>
                TopCrix11 may contain links to other Internet sites owned and
                operated by third parties. Users' use of each of those sites is
                subject to the conditions, if any, posted by the sites.
                TopCrix11 does not have right to control over any Internet
                websites apart from TopCrix11 and cannot be held responsible for
                any content published or curated at any 3rd party Internet
                website. TopCrix11's inclusion of 3rd party content or links to
                3rd party Internet websites is not endorsed by TopCrix11 of such
                3rd party Internet websites.
              </li>
              <li>
                Users' correspondence, transactions/offers or related activities
                with third parties, including payment providers and verification
                service providers, are dedicatedly between Users and that third
                party. Users' correspondence, transactions and usage of the
                services/offers of such third party matters on the terms and
                conditions, policies and other service terms adopted/implemented
                by such third party, and the Users are solely responsible for
                reviewing the same prior to transacting or availing of the
                services/offers of such third party. TopCrix11 will not be
                responsible or liable for any loss or damage of any sort
                incurred as a result of any such transactions/offers with third
                parties. Any questions, complaints, or claims related to any
                third party product or service should be confirmed or enquired
                from the appropriate vendor.
              </li>
              <li>
                You represent that you are 18 years of age or older to
                participate in any Cash Games and are also otherwise competent
                to enter into transactions with other users and TopCrix11. You
                are aware that participation in the Games organized by us
                ("Activity") may result in financial loss to you. With full
                knowledge of the facts and circumstances surrounding this
                Activity, you are voluntarily participating in the Activity and
                assume all responsibility for and risk resulting from your
                participation, including all risk of financial loss. You agree
                to indemnify and hold TopCrix11, its employees, directors,
                officers, and agents harmless concerning all claims and costs
                associated with your participation in the Activity.
              </li>
              <li>
                TopCrix11 contains content that is created by TopCrix11 as well
                as content provided by 3rd parties. We as TopCrix11 do not
                guarantee the accuracy, integrity, quality of the content
                provided or furnished or published by third parties and such
                content may not relied upon by the Users in utilizing the
                TopCrix11 Services provided on TopCrix11 including while
                participating in any of the contests hosted on TopCrix11.
              </li>
              </ul>
              <h2 className="title">5. Privacy Policy</h2>
              <p>
                All information collected from users, such as registration and
                credit card information, is subject to TopCrix11's Privacy
                Policy which is available at Privacy Policy published on the
                Portal.
              </p>

              <h2 className="title">6. User Conduct</h2>
              <ul>
              <li>
                Users agree to follow these Terms and Conditions, other
                specified rules, regulations and terms of use of the website. In
                the event user does not keep to these Terms and Conditions and
                all other rules, regulations and terms of use, TopCrix11 may, at
                its exclusive discretion, take necessary remedial action,
                including but not limited to:
              <ul className="alfabetic">
                <li>
                  restricting, suspending, or terminating any User's access to
                  all or any part of TopCrix11 Services;
                </li>
                <li>
                  deactivating or deleting a User's account and all related
                  information and user account files. Any amount remaining
                  unused in the User's Game account or Winnings Account on the
                  date of deactivation or deletion will be transferred to the
                  User's bank account on record with TopCrix11 subjected to a
                  processing fee (if any) applicable on such transfers as set
                  out herein; or
                </li>
                <li>refraining from awarding any prize(s) to such User.</li>
              </ul>
              </li>
              <li>
                Users undertake to provide authentic, accurate, current and
                complete information at the time of registration and at all
                other times (as required by TopCrix11). Users further agree to
                update and keep updated their registration information
              </li>
              <li>
                Users affirm that the address related details provided, it will
                be treated as “Address on record” of the user for the purposes
                of the Goods and Service Tax Act. In the event of the change in
                such details, the user will get a prompt to update his/her
                profile on the TopCrix11 platform or write to TopCrix11 at
                Support.
              </li>
              <li>
                A User is bound to not to register or operate more than one User
                account with TopCrix11.
              </li>
              <li>
                Users are approved to ensure that they can receive all
                communication from TopCrix11 by marking emails or sending SMSs
                from TopCrix11 as part of their "safe senders" list. TopCrix11
                shall not be held liable if any e-mail/SMS remains unread by a
                User as a result of such e-mail getting delivered to the User's
                junk or spam folder.
              </li>
              <li>
                Any password issued by TopCrix11 to a Users are requested to not
                to reveal it to anyone else. Users are bound to not use anyone
                else's password. Users are responsible for maintaining the
                confidentiality of their accounts and passwords. Users approve
                to immediately notify TopCrix11 of any unauthorized use of their
                passwords or accounts or any other breach of security.
              </li>
              <li>
                Users agree to exit/log-out of their accounts at the end of each
                session. TopCrix11 will not be responsible for any loss or
                damage that may result if the User fails to act in accordance
                with these requirements.
              </li>
              <li>
                Users comply with not to use cheats, exploits, automation,
                software, bots, hacks or any unauthorized third party software
                designed to modify or interfere with TopCrix11 Services and/or
                TopCrix11 experience or assist in such activity.
              </li>
              <li>
                Users affirm not to copy, modify, rent, lease, loan, sell,
                assign, distribute, reverse engineer, grant a security interest
                in, or otherwise transfer any right to the technology or
                software underlying TopCrix11 or TopCrix11’s Services.
              </li>
              <li>
                Users affirm that without TopCrix11's express written consent,
                they shall not modify or cause to be modified any files or
                software that are part of TopCrix11's Services.
              </li>
              <li>
                Users agree not to disrupt, overburden, or aid or assist in the
                disruption or overburdening of (a) any computer or server used
                to offer or support TopCrix11 or the TopCrix11’s Services (each
                a "Server"); or (2) the enjoyment of TopCrix11 Services by any
                other User or person.
              </li>
              <li>
                Users agree not to institute, assist or become involved in any
                type of attack, including without limitation to distribution of
                a virus, denial of service, or other attempts to disrupt
                TopCrix11 Services or any other person's use or enjoyment of
                TopCrix11 Services.
              </li>
              <li>
                Users are subjected to not to attempt for gaining unauthorized
                access to the User accounts, Servers or networks connected to
                TopCrix11 Services by any means other than the User interface
                provided by TopCrix11, including but not limited to, by
                circumventing or modifying, attempting to circumvent or modify,
                or encouraging or assisting any other person to circumvent or
                modify, any security, technology, device, or software that
                underlies or is part of TopCrix11 Services.
              </li>
              <li>
                Users shall not publish any content that is patently false and
                untrue, and is written or published in any form, with the intent
                to mislead or harass a person, entity or agency for financial
                gain or to cause any injury to any person.
              </li>
              <li>
                Without limiting the foregoing, Users agree not to use TopCrix11
                for any of the following:
              <ul className="ul-circle">
                <li>
                  To engage in any obscene, offensive, indecent, racial,
                  communal, anti-national, objectionable, defamatory or abusive
                  action or communication;
                </li>
                <li>
                  To harass, stalk, threaten, or otherwise violate any legal
                  rights of other individuals;
                </li>
                <li>
                  To publish, post, upload, e-mail, distribute, or disseminate
                  (collectively, "Transmit") any inappropriate, profane,
                  defamatory, infringing, obscene, indecent, or unlawful
                  content;
                </li>
                <li>
                  To Transmit files that contain viruses, corrupted files, or
                  any other similar software or programs that may damage or
                  adversely affect the operation of another person's computer,
                  TopCrix11, any software, hardware, or telecommunications
                  equipment;
                </li>
                <li>
                  To advertise, offer or sell any goods or services for any
                  commercial purpose on TopCrix11 without the express written
                  consent of TopCrix11;
                </li>
                <li>
                  To download any file, recompile or disassemble or otherwise
                  affect our products that you know or reasonably should know
                  cannot be legally obtained in such manner;
                </li>
                <li>
                  To falsify or delete any author attributions, legal or other
                  proper notices or proprietary designations or labels of the
                  origin or the source of software or other material;
                </li>
                <li>
                  To restrict or inhibit any other user from using and enjoying
                  any public area within our sites;
                </li>
                <li>
                  To collect or store personal information about other Users;
                </li>
                <li>
                  To interfere with or disrupt TopCrix11, servers, or networks;
                </li>
                <li>
                  To impersonate any person or entity, including, but not
                  limited to, a representative of TopCrix11, or falsely state or
                  otherwise misrepresent User's affiliation with a person or
                  entity;
                </li>
                <li>
                  To forge headers or manipulate identifiers or other data in
                  order to disguise the origin of any content transmitted
                  through TopCrix11 or to manipulate User's presence on
                  TopCrix11(s);
                </li>
                <li>
                  To take any action that imposes an unreasonably or
                  disproportionately large load on our infrastructure;
                </li>
                <li>
                  To engage in any illegal activities. You agree to use our
                  bulletin board services, chat areas, news groups, forums,
                  communities and/or message or communication facilities
                  (collectively, the "Forums") only to send and receive messages
                  and material that are proper and related to that particular
                  Forum.
                </li>
                <li>
                  To engage in any action that threatens the unity, integrity,
                  defense, security or sovereignty of India, friendly relations
                  with foreign States, or public order, or causes incitement to
                  the commission of any cognisable offense or prevents
                  investigation of any offense or is insulting other nations.
                </li>
              </ul>
              </li>
              <li>
                If TopCrix11 considers a username obscene, indecent, abusive or
                that might subject TopCrix11 to public disparagement or scorn,
                or a name which is an official team/league/franchise names
                and/or name of any sporting personality, as the case may be,
                TopCrix11 reserves the right, without prior notice to the User,
                to restrict usage of such names, which in TopCrix11’s opinion
                fall within any of the said categories and/or change such
                username and intimate the User or delete such username and posts
                from TopCrix11, deny such User access to TopCrix11, or any
                combination of these options.
              </li>
              <li>
                Unauthorized access to TopCrix11 is considered to be a breach of
                these Terms and Conditions, and a violation of the law. Users
                are recommended not to access TopCrix11 by any means other than
                through the interface that is provided by TopCrix11 for use in
                accessing TopCrix11 services. Users agree not to use any
                automated means, including, without limitation, agents, robots,
                scripts, or spiders, to access, monitor, or copy any part of our
                sites, except those automated means that we have approved in
                advance and in writing.
              </li>
              <li>
                Use of TopCrix11 is subject to existing laws and legal
                processes. Nothing contained in these Terms and Conditions shall
                limit TopCrix11's right to comply with governmental, court, and
                law-enforcement requests or requirements relating to Users' use
                of TopCrix11
              </li>
              <li>
                <strong>Users may reach out to TopCrix11 through -</strong>
                <ul>
                <li>
                Support if the user has any concerns with regard to a match
                and/or contest within Forty Eight (48) hours of winner
                declaration for the concerned contest.
              </li>
                </ul>
              </li>
              
              <li>
                Minors (Persons below the age of eighteen (18) years) are not
                allowed to participate in any of the contests, games (by
                whatever name called) on the TopCrix11 Platform and App. The
                Users will have to disclose their real age at the time of
                getting access into the TopCrix11 Platform.
              </li>
              <li>
                TopCrix11 may not be held responsible for any content
                contributed by Users on the TopCrix11.
              </li>
              <li>
                User hereby confirms that he / she is participating in a
                contest/league/match/tournament in personal capacity, at their
                own risk and not in the course of business and /or profession.
              </li>
              </ul>

              <h2 className="title">7. Conditions of Participation:</h2>
              <p>
                By entering a Contest, the user agrees to be compelled by these
                Terms and the decisions of TopCrix11. Subject to the terms and
                conditions stipulated herein below, the Company, at its sole and
                absolute discretion, may disqualify any user from a Contest,
                refuse to award benefits or prizes and require the return of any
                prizes, if the user engages themselves in unfair conduct, which
                the Company deems to be improper, unfair or otherwise adverse to
                the operation of the Contest or is in any way detrimental to
                other Users which includes, but is not limited to
              </p>
              <ul>
                <li>
                  Falsifying one's' own personal information (including, but not
                  limited to, name, email address, bank account details and/or
                  any other information or documentation as may be requested by
                  TopCrix11 to enter a contest and/or claim a prize/winning.;
                </li>
                <li>
                  Engaging or participating in any type of financial fraud or
                  misrepresentation including unauthorized use of credit/debit
                  instruments, payment wallet accounts etc. to enter a Contest
                  or claim a prize. It is expressly clarified that the onus to
                  prove otherwise shall solely lie on the user.;
                </li>
                <li>
                  Collaborate with any other user(s) or engaging in any type of
                  syndicate play;
                </li>
                <li>Any violation to Contest rules or the Terms of Use;</li>
                <li>
                  Accumulating points or prizes through unauthorized methods
                  such as automated bots, or other automated means;
                </li>
                <li>
                  Using automated means (including but not limited to harvesting
                  bots, robots, parser, spiders or screen scrapers) to obtain,
                  collect or access any information on the Website or of any
                  User for any purpose
                </li>
                <li>
                  Any type of Cash Bonus misuse, misuse of the Invite Friends
                  program, or misuse of any other offers or promotions;
                </li>
                <li>
                  Tampering the rules decided by a Contest administration or
                  trying to in any way tamper with the computer programs or any
                  security measure associated with a Contest;
                </li>
                <li>
                  Obtaining other users’ information without their express
                  consent and/or knowledge and/or spamming other users (Spamming
                  may include but shall not be limited to send unsolicited
                  emails to users, sending bulk emails to TopCrix11 Users,
                  sending unwarranted email content either to selected Users or
                  in bulk); or
                </li>
                <li>
                  Abusing the Website in any way (‘unparliamentary language,
                  slangs or disrespectful words’ are some of the examples of
                  Abuse)
                </li>
              </ul>
              <p>
                It is clarified that in case a User is found to be in violation
                of this policy, TopCrix11 reserves its right to initiate
                appropriate Civil/Criminal remedies as it may be advised other
                than forfeiture and/or recovery of prize money if any.
              </p>

              <h3 className="title">Registration for a contest</h3>
              <ul>
              <li>
                In order to register for the Contest(s), Participants are
                required to accurately provide the following information:
                <ul>
                  <li>Full Name</li>
                  <li>Team Name(s)</li>
                  <li>E-mail address</li>
                  <li>Password</li>
                  <li>State of Residence</li>
                  <li>Gender</li>
                  <li>Date of birth</li>
                </ul>
              </li>
              <li>
                Participants are also required to confirm that they have read,
                and shall abide by, these Terms and Conditions.
              </li>
              <li>
                In the event a Participant indicates, while entering an address,
                that he/she is a resident of either{" "}
                <strong>
                  Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana
                </strong>{" "}
                such Participant will not be permitted to proceed to sign up for
                any match in the paid version of the Contest as described below.
              </li>
              <li>
                Once the Participants have entered the above information, and
                clicked on the "register" tab, and such Participants are above
                the age of 18 years, they are sent an email confirming their
                registration and containing their login information as they
                agreed at the time of registration.{" "}
              </li>
              </ul>
              <h2 className="title">9. Contest(s), Participation and Prizes</h2>
              <ul>
              <li>
                As part of its services, TopCrix11 may make available the
                contest(s) on the TopCrix11 platform.
              </li>
              <li>
                Currently, Fantasy Cricket game contests are made available on
                TopCrix11 platform. Individual users wishing to participate in
                the such contest ("“Participants"”) are invited to create their
                own fantasy teams ("“Team/s"”) consisting of real life
                cricketers (as applicable) involved in the real-life Cricket
                match (as applicable), series or tournament (each a "Sport
                Event") to which the fantasy game relates. TopCrix11 offers its
                platform to Participants for fantasy game Contest(s) being
                created relating to each Sport Event, and Participants can
                participate in such Contest(s) with their Teams.
              </li>
              <li>
                Based on the match circumstances, the participants can edit
                their teams till the official match start time as declared by
                the officials of the Sport Event or adjusted deadline, as
                specified below.
              <ul>
                <li>
                  TopCrix11 reserves the right to drop a specific round or
                  adjust the deadline of a round in certain specific, uncertain
                  scenarios, which are beyond TopCrix11’s reasonable control,
                  including but not limited to the ones’ mentioned herein below:
                  <ol className="mt-5">
                    <li>
                      Actual match start time is before the official deadline:
                      <ul className="mt-4">
                        <li>
                          All sports other than Cricket-
                          <ul className="ul-circle mt-4">
                            <li>
                              <strong>
                                TopCrix11 holds the right to adjust the deadline
                                to a maximum of 10 minutes post actual match
                                start time. In case the actual match start time
                                is more than 10 minutes after the official
                                deadline, the contest will be abandoned.
                              </strong>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Cricket -
                          <ul className="mt-4">
                            <li>
                              <strong>
                                TopCrix11 has the right to adjust the deadline
                                by a Maximum of 10 minutes or 3 overs bowled,
                                whichever is less, before the official match
                                start time.
                              </strong>
                            </li>
                            <li>
                              <strong>
                                In cases where official match time cannot be
                                verified by TopCrix11 through reliable and/or
                                publicly available sources, TopCrix11 reserves
                                the right to adjust the deadline to such a time
                                by which a maximum of 3 overs in the given match
                                are bowled.
                              </strong>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                        Actual match start time is after the official deadline:
                      <p className="mt-4">
                        TopCrix11 reserves the right to extend the deadline or
                        abandon the contest/game based on the circumstances such
                        as delayed toss, interruption on account of weather,
                        non-appearance of teams, technical/equipment glitches
                        causing delays etc.
                      </p>
                      <ul>
                      <li>
                        TopCrix11 shall endeavor to send communications through
                        emails and/or SMS communication, about any such change
                        as is contemplated in the aforementioned paragraphs to
                        keep the User updated.
                      </li>
                      <li>
                        Teams’ score is calculated on the basis of the real life
                        'cricketers' (as applicable) performances at the end of
                        a designated match, match or tournament of the
                        Contest(s). The Participant(s) whose Team(s) have
                        achieved the highest aggregate score(s) in the
                        Contest(s) shall be declared winners ("Winners' '). In
                        certain pre-specified Contests, there may be more than
                        one Winner and distribution of prizes to such Winners
                        will be in increasing order of their Team's aggregate
                        score at the end of the designated match(s) of the
                        Contests.
                      </li>
                      <li>
                        The Contest(s) across the TopCrix11 Services shall, in
                        addition to the Terms and Conditions, rules and
                        regulations mentioned herein, be governed by:
                        <ul>
                          <li>
                            "Fantasy Rules" available at{" "}
                            <Link to="/games/fantasy-cricket/how-to-play">
                              How To Play - Fantasy Cricket
                            </Link>
                          </li>
                          <li>
                            Other rules and regulations (including rules and
                            regulation in relation to any payments made to
                            participate in the Contest(s); and all Participants
                            agree to abide by the same.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Currently, there are paid versions of the Contest(s)
                        made available on TopCrix11 platform. Users may
                        participate in the Contest(s) by paying the
                        pre-designated amount as provided on the relevant
                        Contest page. The ‘pre-designated amount’ means and
                        includes pre-determined platform fee for accessing
                        TopCrix11 services and pre-determined participant’s
                        contribution towards prize money pool. The Participant
                        with the highest aggregate points at the end of the
                        predetermined match shall be eligible to win a
                        pre-designated prize which is disbursed out of prize
                        money pool, as stated on the relevant Contest(s) page.
                      </li>
                      <li>
                        A Participant may create different Teams for
                        participation in Contest(s) in relation to a Sport Event
                        across the TopCrix11 Services. However, unless TopCrix11
                        specifies otherwise in relation to any Contest
                        ("“Multiple Entry Contest"”), Participants acknowledge
                        and agree that they may enter only one Team in any
                        Contest offered in relation to a Sport Event. In case of
                        Multiple Entry Contest(s), a Participant may enter more
                        than one Team in a single Multiple Entry Contest. In
                        addition, it is expressly clarified that TopCrix11 may,
                        from time to time, restrict the maximum number of Teams
                        that may be created by a single user account (for each
                        format of the contest) or which a single User account
                        may enter in a particular Multiple Entry Contest, in
                        each case to such number as determined by TopCrix11 in
                        its sole discretion.
                      </li>
                      <li>
                        Participants are subjected to pay a pre-designated
                        amount for participating in the contest(s) being created
                        on the TopCrix11 platform. In the event a Participant
                        indicates, while entering an address, that he/she is a
                        resident of either{" "}
                        <strong>
                          Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or
                          Telangana
                        </strong>{" "}
                        such Participant will not be permitted to proceed to
                        sign up for the match or contest and may not participate
                        in any paid version of the Contest(s).
                      </li>
                      <li>
                        In two members and above public contests, where all
                        participants have entered the contest with the exact
                        same teams, including the captain and vice-captain in
                        such event, contest prize money will be equally divided
                        amongst all participants and the amount shall be
                        deposited in the TopCrix11 winning account of all
                        participants and the remaining amount shall be credited
                        in the Cash Bonus account of such participants. In the
                        event a user is found to be violating this policy, the
                        Company reserves the right to initiate appropriate
                        action against such users as it deems fit, which shall
                        also include forfeiture of the Cash Bonus Amount.
                      </li>
                      <li>
                        In Starting Lineup Feature : Purpose of indicating a
                        player's inclusion in final starting lineup is only to
                        provide information and assist a user in selecting
                        his/her team on TopCrix11. While indicating a Player's
                        inclusion in the starting lineup is given by TopCrix11
                        on the basis of information/data received through feed
                        providers, publicly available information. Users are
                        advised to do a thorough research of their own from
                        official sources and/or other available sources of
                        information. TopCrix11, shall not take any liability, if
                        a player earlier indicated as 'Playing' does not play or
                        start for any reason whatsoever.
                      </li>
                      <li>
                        Any substitutes on the field will not be awarded points
                        for any contribution they make. However, 'Concussion or
                        COVID-19 or X Factor or Impact Player Substitutes' will
                        ONLY be awarded points four (4) points for making an
                        appearance in a match in addition to points for any
                        contribution they make as per the Fantasy Points System
                        (w.e.f Oct 9 2022).
                      </li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ul>
              </li>
              </ul>

              <h2>10. Contest Formats</h2>
              <p>
                Currently two formats of contest(s) are made available on
                TopCrix11 platform (1) Public Contest where Users can
                participate in a Contest with other Users without any
                restriction on participation and (2) private contests, where
                Users can invite specific Users into a Contest and restrict
                participation to such invited Users.
              </p>
              <p>
                All rules applicable to Contest(s) as set out in this Privacy
                Policy are applicable to both formats of the Contest(s). Users
                by participating in a Contest(s) hereby authorize TopCrix11 to
                appoint a third party/Trustee/Escrow Agent to manage users funds
                on users behalf.
              </p>
              <p>TopCrix11 to appoint a third party/Trustee/Escrow Agent to manage users funds on users behalf.</p>
              <h4>Public contest</h4>
              <p>
                TopCrix11 may create this format of the Contest(s) as a paid
                format and the Winner will be determinable at the end of the
                match as per rule of the contests. The number of Participants
                required to make the Contest(s) operational will be
                pre-specified and once the number of Participants in such
                Contest(s) equals the pre-specified number required for that
                Contest(s), such Contest(s) shall be operational. In case the
                number of Participants is less than the pre-specified number at
                the time of commencement of the match, such Contest(s) will not
                be operational and the pre-designated amount paid by each
                Participant shall be returned to the account of such User
                without any charge or deduction. In certain Contests across the
                TopCrix11 Services, designated as “Guaranteed contests”, the
                Contest(s) shall become operational only when a minimum of two
                users join a Guaranteed Contest. The pre-specified number of
                winners to be declared in such Contest(s), even if all available
                Participant slots (as pre-specified in relation to the
                Contest(s)) remain unfilled. It is clarified that
                notwithstanding the activation of such Contest(s), Participants
                can continue to join such Contest(s) till either (i) all
                available Participant slots of such Contest(s) are filled or
                (ii) the match to which the Contest (s) relates commences,
                whichever is earlier. In the event of a shortfall in the number
                of participants joining the Guaranteed Contest, TopCrix11 shall
                continue with such contests and the short fall in the prize pool
                shall be borne by TopCrix11.
              </p>

              <h4>Private contest</h4>
              <p>
                In the Private contest format of the Contest(s), TopCrix11
                enables Users to create a contest (“Private contest") and invite
                other users, whether existing Users or otherwise, ("Invited
                User") to create Teams and participate in the Contest(s). The
                User creating the Private contest shall be bound to pay the
                pre-designated amount for such Private contest and thereby join
                that Private contest and shall supply a name for the Private
                contest and be provided with a unique identification code
                ("contest Code") (which will be issued to the account of such
                User). The User affirm that once the Private contest is created
                no change shall be permitted in the terms or constitution of the
                Private contest, except for a change in the name of the contest.
                The User creating the Private contest shall provide TopCrix11
                with the email address or Facebook account username of Invited
                Users to enable TopCrix11 to send a message or mail inviting
                such Invited User to register with TopCrix11 (if necessary) and
                participate in the Private contest in relation to which the
                invite has been issued.
              </p>
              <p>
                In order to participate in the Private contest, an Invited User
                shall input the contest Code associated with the Private contest
                and pay the pre-designated amount for the Private contest. Once
                the number of Participants in a Private contest equals the
                number of pre-specified Participants for that Private contest,
                the Private contest shall be rendered operative and no other
                Invited Users or Users shall be permitted to participate in the
                Private contest. In the event that any Private contest does not
                contain the pre-specified number of Participants for that
                Private contest{" "}
                <strong>
                  within 1 hour prior to the commencement of the match/Contest,
                  the Platform will initiate an automatic refund of the amount
                  deposited. Such refund shall be processed after the expiry of
                  the deadline for filing of participants for such Private
                  Contest.
                </strong>
              </p>
              <p>
                It is clarified that the participation of Invited Users in any
                Private contest is subject to the pre-specified number of
                Participants for that Private contest, and TopCrix11 shall not
                be liable to any person for the inability of any Invited User to
                participate in any Private contest due to any cause whatsoever,
                including without limitation due to a hardware or technical
                malfunction or lack of eligibility of such Invited User to
                participate in the Contest(s).
              </p>

              <h2>11. Legality of Game of Skill</h2>
              <p>
                Games of skill are legal, as they are excluded from the ambit of
                Indian gambling legislations including, the Public Gambling Act
                of 1867.The Indian Supreme Court in the cases of State of Andhra
                Pradesh v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan
                v. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game
                in which success depends predominantly upon the superior
                knowledge, training, attention, experience and adroitness of the
                player shall be classified as a game of skill.
              </p>
              <p>
                The Contest (s) described above (across the TopCrix11 Services)
                are games of skill as success of Participants depends primarily
                on their superior knowledge of the games of cricket statistics,
                knowledge of players' relative form, players' performance in a
                particular territory, conditions and/or format (such as ODIs,
                test cricket and Twenty20 in the cricket fantasy game),
                attention and dedication towards the Contest(s) and adroitness
                in playing the Contest(s). The Contest(s) also requires
                Participants to field well-balanced sides with limited resources
                and make substitutions at appropriate times to gain the maximum
                points.
              </p>
              <p>
                By participating in this Contest(s), each Participant
                acknowledges and agrees that he/she is participating in a game
                of skill.
              </p>

              <h2>12. Eligibility</h2>
              <p>
                The Contest(s) are open only to persons above the age of 18
                years not for minors.
              </p>
              <p>
                The Contest(s) are open only to persons currently residing in
                India.
              </p>
              <p>
                TopCrix11 may, in accordance with the laws prevailing in certain
                Indian states, bar individuals residing in those states from
                participating in the Contest(s).{" "}
                <strong>
                  {" "}
                  Currently, individuals residing in the Indian states of Andhra
                  Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana may not
                  participate in the paid version of the Contest as the laws of
                  these states are unclear/ bar persons from participating in
                  games of skill where participants are required to pay to
                  enter.
                </strong>
              </p>
              <p>
                Persons who wish to participate must have a valid email address.
              </p>
              <p>
                TopCrix11 may on receipt of information bar a person from
                participation and/or withdrawing winning amounts if such person
                is found to be one with insider knowledge of participating teams
                in any given contests/match, organizing boards, leagues etc.
              </p>
              <p>
                Only those Participants who have successfully registered on the
                TopCrix11 as well as registered prior to each match in
                accordance with the procedure outlined above shall be eligible
                to participate in the Contest and win prizes.
              </p>

              <h2>13. Payment Terms</h2>
              <p>
                Any respective transaction(s) entered into on the TopCrix11
                platform, including making a payment to participate in the paid
                versions of Contest(s), Users agree to be bound by the following
                payment terms:
              </p>
              <ul >
                <li>
                  The payment of pre-designated amount or contest fee or match
                  fee Users make to participate in the Contest(s) is inclusive
                  of the pre-designated platform fee for access to the TopCrix11
                  Services charged by TopCrix11 and pre-determined participant’s
                  contribution towards prize money pool.
                </li>
                <li>
                  Subject to these Terms and Conditions, all amounts collected
                  from the User are held in a separate non-interest earning bank
                  Accounts.{" "}
                  <strong>
                    The said accounts are operated by a third party appointed by
                    TopCrix11
                  </strong>{" "}
                  in accordance with Clause 10 of these Terms and Conditions.
                  From these bank accounts, the payouts can be made to (a) Users
                  (towards their withdrawals), (b) TopCrix11 (towards its
                  Platform Fees) and to (c) Government (towards TDS on Winnings
                  Amount). TopCrix11 receives only its share of the platform
                  Fees through the said Escrow Agent.
                </li>
                <li>
                  The TopCrix11 holds the right to charge a Platform Fee, which
                  would be specified and notified by TopCrix11 on the Contest
                  page, being created on TopCrix11 platform, prior to a User's
                  joining of such a Contest. The Platform Fee (inclusive of
                  applicable Goods and Service Tax (“GST”) thereon) will be
                  debited from the User’s account balance and TopCrix11 shall
                  issue an invoice for such debit to the User. Users can view
                  the GST paid on each paid contest through invoices on ‘My
                  Transaction’ page. The GST value listed on the user's invoice
                  shall be the final GST amount charged to the user.
                </li>
                <li>
                  The User may participate in a Contest wherein the User has to
                  contribute a pre-specified contribution towards the Prize
                  Money Pool of such Contest, which will be passed on to the
                  Winner(s) of the Contest after the completion of the Contest
                  as per the terms and conditions of such Contest. It is
                  clarified that TopCrix11 has no right or interest in this
                  Prize Money Pool, and only acts as an intermediary engaged in
                  collecting and distributing the Prize Money Pool in accordance
                  with the Contest terms and conditions. The amount to be
                  paid-in by the User towards the Prize Money Pool would also be
                  debited from the User’s account balance maintained with the
                  Trustee.
                </li>
                <li>
                  Any user availing TopCrix11 services are provided with two
                  categories of accounts for the processing and reconciliation
                  of payments: (i) 'Unutilized' Account, (ii) Winnings Account.
                  It is clarified that in no instance the transfer of any
                  amounts in the User's accounts to any other category of
                  account held by the user or any third party account, including
                  a bank account held by a third party:
                  <ul>
                    <li>
                      User's winnings in any Contest will reflect as credits to
                      the User's Winnings Account.
                    </li>
                    <li>
                      User’s remitting the amount through the designated payment
                      gateway shall be credited to User’s Unutilized Account’.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  Each time a User participates in any contest on TopCrix11
                  platform, the pre-designated amount shall be debited in the
                  User’s account. In debiting amounts from the User’s accounts
                  towards the pre-designated amount of such user shall be
                  debited from the User’s Unutilized Account and thereafter, any
                  remaining amount of participation fee shall be debited from
                  the User’s Winning Account.
                </li>
                <li>
                  In case there is any amount remaining to be paid by the User
                  in relation to such User’s participation in any match(s) or
                  Contest(s), the User will be taken to the designated payment
                  gateway to give effect to such payment. In case any amount
                  added by the User through such payment gateway exceeds the
                  remaining amount of the pre-designated amount, the amount in
                  excess shall be transferred to the User’s ‘Unutilized’ Account
                  and will be available for use in participation in any match(s)
                  or Contest(s).
                </li>
                <li>
                  Debits from the ‘Unutilized’ Account for the purpose of
                  enabling a user’s participation in a Contest shall be made in
                  order of the date of credit of amounts in the ‘Unutilized’
                  Account, and accordingly amounts credited into ‘Unutilized’
                  Account earlier in time shall be debited first.
                </li>
                <li>
                  All amounts credited into a User's 'Unutilized' Account must
                  be utilized within 335 days of credit. In case any unutilised
                  amount lies in the 'Unutilized' Account after the completion
                  of 335 days from the date of credit of such amount, TopCrix11
                  reserves the right to forfeit such unutilised amount, without
                  liability or obligation to pay any compensation to the User.
                </li>
                <li>
                  Withdrawal of any amount standing to the User's credit in the
                  Winnings Account may be made by way of a request to TopCrix11.
                  TopCrix11 shall effect an online transfer to the User's bank
                  account on record with TopCrix11 within a commercially
                  reasonable period of time. Such transfer will reflect as a
                  debit to the User's Winnings Account. TopCrix11 shall not
                  charge any processing fee for the online transfer of such
                  amount from the Winnings Account to the User's bank account on
                  record with TopCrix11. Users are requested to note that they
                  will be required to provide valid photo identification and
                  address proof documents for proof of identity and address in
                  order for TopCrix11 to process the withdrawal request. The
                  name mentioned on the User's photo identification document
                  should correspond with the name provided by the User at the
                  time of registration on TopCrix11, as well as the name and
                  address existing in the records of the User's bank account as
                  provided to TopCrix11. In the event that no bank account has
                  been registered by the User against such User's account with
                  TopCrix11, or the User has not verified his/her User account
                  with TopCrix11, to TopCrix11's satisfaction and in accordance
                  with these Terms and Conditions, and in case the User fails to
                  register a bank account with his/her User Account and/or to
                  verify his/her User Account, TopCrix11 shall not transfer any
                  Winning amounts to the User.
                </li>
                <li>
                  Further, in order to conduct promotional activities, TopCrix11
                  may gratuitously issue Cash Bonus to the User for the purpose
                  of participation in any Contest(s) and no User shall be
                  permitted to transfer or request the transfer of any amount
                  into the Cash Bonus. The usage of any Cash Bonus issued shall
                  be subject to the limitations and restrictions, including
                  without limitation, restrictions as to time within which such
                  Cash Bonus must be used, as applied by TopCrix11 and notified
                  to the User at the time of issue of such amount. The issue of
                  any Cash Bonus to the user is subject to the sole discretion
                  of TopCrix11 and cannot be demanded by any User as a matter of
                  right. The issue of any Cash Bonus by TopCrix11 on any day
                  shall not entitle the user to demand the issuance of such Cash
                  Bonus at any subsequent period in time nor create an
                  expectation of recurring issue of such Cash Bonus by TopCrix11
                  to such User. The Cash Bonus granted to the user may be used
                  by such User for the purpose of setting off against the
                  contribution to the prize pool in any Contest, in accordance
                  with these Terms and Conditions. The Cash Bonus shall not be
                  withdraw-able or transferrable to any other account of the
                  User, including the bank account of such User, or of any other
                  User or person, other than as part of the winnings of a User
                  in any Contest(s). In case the User terminates his/her account
                  with TopCrix11 or such account if terminated by TopCrix11, all
                  Cash Bonus granted to the user shall return to TopCrix11 and
                  the User shall not have any right or interest on such points.
                </li>
                <li>
                  <strong>
                    All Cash Bonus credited in the User account shall be valid
                    for a period of 14 days from the date of credit. The
                    unutilized Cash Bonus shall expire at the end of 14 days
                    from the date of credit.
                  </strong>
                </li>
                <li>
                  Users agree that once a transaction is confirmed on TopCrix11,
                  they shall be bound by and make payment for that transaction.
                </li>
                <li>
                  The User acknowledges that subject to time taken for bank
                  reconciliations and such other external dependencies that
                  TopCrix11 has on third parties, any transactions on TopCrix11
                  Platform may take up to 24 hours to be processed. Any amount
                  paid or transferred into the User's 'Unutilized' Account or
                  Winnings Account may take up to 24 hours to reflect in the
                  User's 'Unutilized' Account or Winnings Account balance.
                  Similarly, the utilization of the Cash Bonus or money debited
                  from 'Unutilized' Account or Winnings Account may take up to
                  24 hours to reflect in the User's 'Unutilized' Account or
                  Winnings Account balance. Users agree not to raise any
                  complaint or claim against TopCrix11 in respect of any delay,
                  including any lost opportunity to join any Contest or match
                  due to delay in crediting of transaction amount into any of
                  the User's accounts.
                </li>
              </ul>
              <p>
                Upon confirmation of a transaction Users agree that it is final
                and no cancellation is permissible.
              </p>
              <p>
                TopCrix11 may, in certain exceptional circumstances and at its
                sole and absolute discretion, refund the amount to the User
                after deducting applicable cancellation charges and taxes. At
                the time of the transaction, Users may also be required to take
                note of certain additional terms and conditions and such
                additional terms and conditions shall also govern the
                transaction. To the extent that the additional terms and
                conditions contain any clause that is conflicting with the
                present terms and conditions, the additional terms and
                conditions shall prevail.
              </p>

              <h2>14. Tabulation of fantasy points</h2>
              <p>
                TopCrix11 may obtain the score feed and other information
                required for the computation and tabulation of fantasy points
                from third party service provider(s) and/or official website of
                the match organizer. In the rare event that any error in the
                computation or tabulation of fantasy points, selection of
                winners, abandonment of a match etc., as a result of
                inaccuracies in or incompleteness of the feed provided by the
                third party service provider and/or official website of the
                match organizer comes to its attention, TopCrix11 shall use best
                efforts to rectify such error prior to the distribution of
                prizes. However, TopCrix11 hereby clarifies that it relies on
                the accuracy and completeness of such third party
                score/statistic feeds and does not itself warrant or make any
                representations concerning the accuracy thereof and, in any
                event, shall take no responsibility for inaccuracies in
                computation and tabulation of fantasy points or the selection of
                winners as a result of any inaccurate or incomplete
                scores/statistics received from such third party service
                provider. Users and Participants agree not to make any claim or
                raise any complaint against TopCrix11 in this respect.
              </p>

              <h2>
                15. Selection and Verification of Winners and Conditions
                relating to the Prizes
              </h2>
              <h4>Selection of Winners</h4>
              <p>
                Winners will be decided on the basis of the scores of the Teams
                in a designated match (which may last anywhere between one day
                and an entire tournament) of the Contest(s). The Participant(s)
                owning the Team(s) with the highest aggregate score in a
                particular match shall be declared the Winner(s). In certain
                pre-specified Contests, TopCrix11 may declare more than one
                Winner and distribute prizes to such Winners in increasing order
                of their Team's aggregate score at the end of the designated
                match of the Contest. The contemplated number of Winners and the
                prize due to each Winner in such Contest shall be as specified
                on the Contest page prior to the commencement of the Contest.
                Users shall be compelled to not to create a team(s) on behalf of
                any other Participant or person. If found or identified by
                TopCrix11 they shall be disqualified. In the event of a tie, the
                winning Participants shall be declared Winners and the prize
                shall be equally divided among such Participants. TopCrix11
                shall not be liable to pay any prize if it is discovered that
                the Winner(s) have not abided by these Terms and Conditions, and
                other rules and regulations in relation to the use of the
                TopCrix11, Contest, "Fantasy Rules", etc.
              </p>
              <h4>Contacting Winners</h4>
              <p>
                Winners shall be contacted by TopCrix11 or the third party
                conducting the Contest on the e-mail address provided at the
                time of registration. The verification process and the documents
                required for the collection of prizes shall be detailed to the
                Winners at this stage. As a general practice, winners will be
                required to provide following documents:
              </p>
              <ul>
                <li>Photocopy of the User's PAN card;</li>
                <li>Photocopy of a government-issued residence proof;</li>
                <li>User's bank account details and proof of the same.</li>
              </ul>
              <p>
                TopCrix11 shall not permit a Winner to withdraw his/her
                prize(s)/accumulated earnings unless the above-mentioned
                documents have been received and verified within the time-period
                stipulated by TopCrix11. The User represents and warrants that
                the documents provided in the course of the verification process
                are true copies of the original documents to which they relate.
              </p>
              <p>
                Participants are required to provide proper and complete details
                at the time of registration. TopCrix11 shall not be responsible
                for communications errors, commissions or omissions including
                those of the Participants due to which the results may not be
                communicated to the Winner.
              </p>

              <p>
                The list of Winners shall be posted on a separate web-page on
                the TopCrix11. The winners will also be intimidated by email.
              </p>

              <p>
                In the event that a Participant has been declared a Winner on
                the abovementioned web-page but has not received any
                communication from TopCrix11, such Participant may contact
                TopCrix11 within the time specified on the webpage.
              </p>
              <h4>Verification Process</h4>
              <p>
                Only those Winners who successfully complete the verification
                process and provide the required documents within the time limit
                specified by TopCrix11 shall be permitted to withdraw/receive
                their accumulated earnings (or any part thereof). TopCrix11
                shall not entertain any claims or requests for extension of time
                for submission of documents.
              </p>

              <p>
                TopCrix11 shall scrutinize all documents submitted and may, at
                its sole and absolute discretion, disqualify any Winner from
                withdrawing his accumulated earnings (or any part thereof) on
                the following matches:
              </p>
              <ul>
                <li>
                  Determination by TopCrix11 that any document or information
                  submitted by the Participant is incorrect, misleading, false,
                  fabricated, incomplete or illegible; or
                </li>
                <li>
                  Participant does not fulfill the Eligibility Criteria
                  specified in Clause 10 above; or{" "}
                </li>
                <li>Any other match.</li>
              </ul>
              <h4>Taxes Payable</h4>
              <p>
                <strong>
                  All prizes shall be subject to deduction of tax (“TDS”) as per
                  the Income Tax Act 1961. The TDS rate prescribed by the
                  Government of India with respect to any prize money amount
                  that is in excess of Rs. 10,000/- is 31.2% . TDS of 31.2%
                  shall be deducted on Net Winnings ( prize money amount -
                  contest entry amount - cash bonus).
                </strong>{" "}
                In case of any revisions by the Government of India to the
                aforementioned rate in the future, TDS will be deducted by
                TopCrix11 in accordance with the then current prescribed TDS
                rate. Winners will be provided TDS certificates in respect of
                such tax deductions. The Winners shall be responsible for
                payment of any other applicable taxes, including but not limited
                to, income tax, gift tax, etc. in respect of the prize money.
              </p>
              <h4>Miscellaneous</h4>
              <p>
                The decision of TopCrix11 with respect to the awarding of prizes
                shall be final, binding and non-contestable.
              </p>
              <p>
                <strong>
                  Participants playing the paid formats of the Contest(s)
                  confirm that they are not residents of any of the following
                  Indian states - Andhra Pradesh, Assam, Nagaland, Odisha,
                  Sikkim or Telangana.
                </strong>{" "}
                If it is found that a Participant playing the paid formats of
                the Contest(s) is a resident of any of the abovementioned
                states, TopCrix11 shall disqualify such Participant and forfeit
                any prize won by such Participant. Further TopCrix11 may, at its
                exclusive discretion, suspend or terminate such a Participant's
                or Users’ account with TopCrix11. Any amount remaining unused in
                the User's Game Account or Winnings Account on the date of
                deactivation or deletion shall be reimbursed to the User by an
                online transfer to the User's bank account on record with
                TopCrix11, subject to the processing fee (if any) applicable on
                such transfers as set out herein.
              </p>

              <p>
                If it is found that a Participant playing the paid formats of
                the Contest(s) is under the age of eighteen (18), TopCrix11
                shall be entitled, at its sole and absolute discretion, to
                disqualify such Participant and forfeit his/her prize. Further,
                TopCrix11 may, at its sole and absolute discretion, suspend or
                terminate such Participant's account.
              </p>

              <p>
                To the extent permitted by law, TopCrix11 makes no
                representations or warranties as to the quality, suitability or
                merchantability of any prizes and shall not be liable in respect
                of the same.
              </p>

              <p>
                TopCrix11 may, at its sole and absolute discretion, vary or
                modify the prizes being offered to winners. Participants shall
                not raise any claim against TopCrix11 or question its right to
                modify such prizes being offered, prior to closure of the
                Contest.
              </p>

              <p>
                TopCrix11 will not bear any responsibility for the
                transportation or packaging of prizes to the respective winners.
                TopCrix11 shall not be held liable for any loss or damage caused
                to any prizes at the time of such transportation.
              </p>

              <p>
                The Winners shall bear the shipping, courier or any other
                delivery cost in respect of the prizes.
              </p>

              <p>
                The Winners shall bear all transaction charges levied for
                delivery of cash prizes.
              </p>

              <p>
                All prizes are non-transferable and non-refundable. Prizes
                cannot be exchanged / redeemed for cash or kind. No cash claims
                can be made in lieu of prizes in kind.
              </p>
              <h2>16. Publicity</h2>
              <p>
                Acceptance of a prize by the Winner constitutes permission for
                TopCrix11, and its affiliates to use the Winner's name,
                likeness, voice and comments for advertising and promotional
                purposes in any media worldwide for purposes of advertising and
                trade without any further permissions or consents and / or
                additional compensation whatsoever.
              </p>

              <p>
                The Winners further undertake that they will be available for
                promotional purposes as planned and desired by TopCrix11 without
                any charge. The exact dates remain the sole{" "}
              </p>

              <p>
                discretion of TopCrix11. Promotional activities may include but
                not be limited to press events, internal meetings and
                ceremonies/functions.
              </p>
              <h2>17. General Conditions</h2>
              <p>
                If it comes to the notice of TopCrix11 that any governmental,
                statutory or regulatory compliances or approvals are required
                for conducting any Contest(s) or if it comes to the notice of
                TopCrix11 that conduct of any such Contest(s) is prohibited,
                then TopCrix11 shall withdraw and / or cancel such Contest(s)
                without prior notice to any Participants or winners of any
                Contest(s). Users agree not to make any claim in respect of such
                cancellation or withdrawal of the Contest or Contest it in any
                manner.
              </p>

              <p>
                Employees are not eligible to participate in any Public
                Contest(s).
              </p>
              <h2>18. Dispute and Dispute Resolution</h2>
              <ul>
                <li>
                  <strong>
                  The courts of competent jurisdiction at Jaipur, Rajasthan, India shall have exclusive jurisdiction to
                  </strong>
                  determine any and all disputes arising out of, or in connection with, the TopCrix11 Services provided by TopCrix11 (including the Contest(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or TopCrix11, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India.
                </li>
                <li>
                In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.
                </li>
                <li>
                  <strong>
                  The place of arbitration shall be Jaipur, Rajasthan, India. All arbitration proceedings shall be
                  </strong>
                  conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
                </li>
                <li>The arbitration award will be final and binding on the Parties, and each Party shall be bound to bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.</li>
                <li>
                Nothing contained in these Terms and Conditions shall prevent TopCrix11 from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard TopCrix11's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of TopCrix11 to pursue any remedy for monetary damages through the arbitration described as per Privacy Policy- TopCrix11.
                </li>
              </ul>

              <h3>Release and Limitations of Liability</h3>
              <ul>
                <li>
                  Users shall access the TopCrix11 Services provided on
                  TopCrix11 voluntarily and at their own risk. TopCrix11
                  reserves right to not to be held under no circumstances or
                  liable on account of any loss or damage sustained (including
                  but not limited to any accident, injury, death, loss of
                  property) by Users or any other person or entity during the
                  course of access to the TopCrix11 Services (including
                  participation in the Contest(s)) or as a result of acceptance
                  of any prize.
                </li>

                <li>
                  By entering the contests and accessing the TopCrix11 Services
                  provided on the Portal, Users hereby release from and agree to
                  indemnify TopCrix11, and/ or any of its directors, employees,
                  partners, associates and licensors, from and against all
                  liability, cost, loss or expense arising out their access to
                  the TopCrix11 Services including (but not limited to) personal
                  injury and damage to property and whether direct, indirect,
                  consequential, foreseeable, due to some negligent act or
                  omission on their part, or otherwise.
                </li>

                <li>
                  TopCrix11 accepts no liability, whether jointly or severally,
                  for any errors or omissions, whether on behalf of itself or
                  third parties in relation to the prizes.
                </li>

                <li>
                  Users shall be solely responsible for any consequences which
                  may arise due to their access of TopCrix11 Services by
                  conducting an illegal act or due to non-conformity with these
                  Terms and Conditions and other rules and regulations in
                  relation to TopCrix11 Services, including provision of
                  incorrect address or other personal details. Users also
                  undertake to indemnify TopCrix11 and their respective
                  officers, directors, employees and agents on the happening of
                  such an event (including without limitation cost of attorney,
                  legal charges etc.) on full indemnity basis for any
                  loss/damage suffered by TopCrix11 on account of such act on
                  the part of the Users.
                </li>

                <li>
                  Users shall indemnify, defend, and hold TopCrix11 harmless
                  from any third party/entity/organization claims arising from
                  or related to such User's engagement with the TopCrix11 or
                  participation in any Contest. In no event shall TopCrix11 be
                  liable to any User for acts or omissions arising out of or
                  related to User's engagement with the TopCrix11 or his/her
                  participation in any Contest(s).
                </li>

                <li>
                  In consideration of TopCrix11 allowing Users to access the
                  TopCrix11 Services, to the maximum extent permitted by law,
                  the Users waive and release each and every right or claim, all
                  actions, causes of actions (present or future) each of them
                  has or may have against TopCrix11, its respective agents,
                  directors, officers, business associates, group companies,
                  sponsors, employees, or representatives for all and any
                  injuries, accidents, or mishaps (whether known or unknown) or
                  (whether anticipated or unanticipated) arising out of the
                  provision of TopCrix11 Services or related to the Contests or
                  the prizes of the Contests.
                </li>
              </ul>
              <h2>20. Disclaimers</h2>
              <ul>
                <li>
                  To the extent permitted under law, neither TopCrix11 nor its
                  parent/holding company, subsidiaries, affiliates, directors,
                  officers, professional advisors, employees shall be
                  responsible for the deletion, the failure to store, the
                  mis-delivery, or the untimely delivery of any information or
                  material.
                </li>
                <li>
                  To the extent permitted under law, TopCrix11 shall not be
                  responsible for any harm resulting from downloading or
                  accessing any information or material, the quality of servers,
                  games, products, TopCrix11 services or sites, cancellation of
                  competition and prizes. TopCrix11 disclaims any responsibility
                  for, and if a User pays for access to one of TopCrix11's
                  Services the User will not be entitled to a refund as a result
                  of, any inaccessibility that is caused by TopCrix11's
                  maintenance on the servers or the technology that underlies
                  our sites, failures of TopCrix11's service providers
                  (including telecommunications, hosting, and power providers),
                  computer viruses, natural disasters or other destruction or
                  damage of our facilities, acts of nature, war, civil
                  disturbance, or any other cause beyond our reasonable control.
                  In addition, TopCrix11 does not provide any warranty as to the
                  content on the TopCrix11(s). TopCrix11(s) content is
                  distributed on an "as is, as available" basis.
                </li>
                <li>
                  Any material accessed, downloaded or otherwise obtained
                  through TopCrix11 is done at the User's discretion,
                  competence, acceptance and risk, and the User will be solely
                  responsible for any potential damage to User's computer system
                  or loss of data that results from a User's download of any
                  such material.
                </li>
                <li>
                  TopCrix11 committed to make best endeavors to ensure that the
                  TopCrix11(s) is error-free and secure, however, neither
                  TopCrix11 nor any of its partners, licensors or associates
                  makes any warranty that:
                  <ul>
                    <li>the TopCrix11(s) will meet Users' requirements,</li>
                    <li>
                      TopCrix11(s) will be uninterrupted, timely, secure, or
                      error free
                    </li>
                    <li>
                      the results that may be obtained from the use of
                      TopCrix11(s) will be accurate or reliable; and
                    </li>
                    <li>
                      the quality of any products, TopCrix11 Services,
                      information, or other material that Users purchase or
                      obtain through TopCrix11com(s) will meet Users'
                      expectations.
                    </li>
                  </ul>
                </li>
                <li>
                  In case TopCrix11 discovers any error, including any error in
                  the determination of Winners or in the transfer of amounts to
                  a User's account, TopCrix11 reserves the right (exercisable at
                  its discretion) to rectify the error in such manner as it
                  deems fit, including through a set-off of the erroneous
                  payment from amounts due to the User or deduction from the
                  User's account of the amount of erroneous payment. In case of
                  exercise of remedies in accordance with this clause, TopCrix11
                  agrees to notify the User of the error and of the exercise of
                  the remedy(ies) to rectify the same.
                </li>

                <li>
                  To the extent permitted under law, neither TopCrix11 nor its
                  partners, licensors or associates shall be liable for any
                  direct, indirect, incidental, special, or consequential
                  damages arising out of the use of or inability to use our
                  sites, even if we have been advised of the possibility of such
                  damages.
                </li>

                <li>
                  Any TopCrix11 Services, events or Contest(s) being hosted or
                  provided, or intended to be hosted on TopCrix11 platform and
                  requiring specific permission or authority from any statutory
                  authority or any state or the central government, or the board
                  of directors shall be deemed canceled or terminated, if such
                  permission or authority is either not obtained or denied
                  either before or after the availability of the relevant
                  TopCrix11 Services, events or Contest(s) are hosted or
                  provided.
                </li>

                <li>
                  To the extent permitted under law, in the event of suspension
                  or closure of any Services, events or Contests, Users
                  (including Participants) shall not be entitled to make any
                  demands, claims, on any nature whatsoever.
                </li>
              </ul>
              <h2>21. Miscellaneous</h2>
              <ul>
                <li>
                  TopCrix11 may be required under certain legislations, to
                  notify User(s) of certain events. User(s) hereby acknowledge
                  and consent that such notices will be effective upon TopCrix11
                  posting them on the TopCrix11 or delivering them to the User
                  through the email address provided by the User at the time of
                  registration. User(s) may update their email address by
                  logging into their account on the TopCrix11. If they do not
                  provide TopCrix11 with accurate information, TopCrix11 cannot
                  be held liable for failure to notify the User.
                </li>

                <li>
                  TopCrix11 is not liable for any delay or failure to perform
                  resulting from causes outside its reasonable control,
                  including but not limited to any failure to perform due to
                  unforeseen circumstances or cause beyond TopCrix11's control
                  such as acts of God, war, terrorism, riots, embargoes, acts of
                  civil or military authorities, fire, floods, accidents,
                  network infrastructure failures, strikes, or shortages of
                  transportation facilities, fuel, energy, labor or materials or
                  any cancellation of any cricket match to which a Contest
                  relates. In such circumstances, TopCrix11 shall also be
                  entitled to cancel any related Contest(s) and to process an
                  appropriate refund for all Participants.
                </li>

                <li>
                  TopCrix11's failure to exercise or enforce any right or
                  provision of these Terms and Conditions shall not constitute a
                  waiver of such right or provision.
                </li>

                <li>
                  Users agree that regardless of any statute or law to the
                  contrary, any claim or cause of action arising out of or
                  related to use of the TopCrix11 or these Terms must be filed
                  within thirty (30) days of such claim or cause of action
                  arising or be forever barred.
                </li>
                <li>
                  <strong>
                    These Terms and Conditions, including all terms, conditions,
                    and policies that are incorporated herein by reference,
                    constitute the entire agreement between the User(s) and
                    TopCrix11 and govern your use of the TopCrix11, superseding
                    any prior agreements that any User may have with TopCrix11.
                  </strong>
                </li>
                <li>
                  If any part of these Terms and Conditions is determined to be
                  indefinite, invalid, or otherwise unenforceable, the rest of
                  these Terms and Conditions shall continue in full force.
                </li>

                <li>
                  TopCrix11 reserves the right to moderate, restrict or ban the
                  use of the TopCrix11, specifically to any User, or generally,
                  in accordance with TopCrix11's policy/policies from time to
                  time, at its sole and absolute discretion and without any
                  notice.
                </li>

                <li>
                  TopCrix11 may, at its sole and absolute discretion,
                  permanently close or temporarily suspend any TopCrix11
                  Services (including any Contest(s)).
                </li>

                <li>
                  TopCrix11 may from time to time conduct/organize
                  promotions/offers on the platform. Any two or more promotions
                  cannot be clubbed together with any other promotions that are
                  running simultaneously on the TopCrix11 platform. Also,
                  promotions/offers cannot be clubbed with Cash Bonus available
                  with any user.
                </li>
              </ul>
              <h2>22. Grievance Redressal Mechanism</h2>
              <ul>
                <li>
                  In case you have any complaints or grievance pertaining to (i)
                  any User Content that you believe violates these Terms (other
                  than an infringement of Intellectual Property Rights) for the
                  reporting mechanism for such claims), (ii) your access to the
                  Platform or (iii) any User Content which you believe is, prima
                  facie, in the nature of any material which is obscene,
                  defamatory towards the complainant or any person on whose
                  behalf you are making the complaint, or is in the nature of
                  impersonation in an electronic form, including artificially
                  morphed images of such individual, please share the same with
                  us by writing to  <a href="mailto:support@topcrix11.com">support@TopCrix11.com</a>
                </li>
                <li>
                  In your complaint or grievance, please include the following
                  information:
                  <ul className="mt-4">
                    <li>
                      Your name and contact details: name, address, contact
                      number and email address;
                    </li>
                    <li>
                      Your relation to the subject matter of the complaint, i.e.
                      complainant or person acting on behalf of an affected
                      person;
                    </li>
                    <li>
                      The name and age of the person aggrieved or affected by
                      the subject matter of the complaint, in case your are
                      acting on behalf of such person and a statement, under
                      penalty of perjury, that you are authorized to act on
                      behalf of such person and to provide such person's
                      personal information to us in relation to the
                      complaint/grievance;
                    </li>
                    <li>
                      Description of the complaint or grievance with clear
                      identification of the User Content in relation to which
                      such complaint or grievance is made;
                    </li>
                    <li>
                      A statement that you believe, in good faith, that the User
                      Content violates these Terms
                    </li>
                    <li>
                      A statement that the information provided in the complaint
                      or grievance is accurate.
                    </li>
                  </ul>
                </li>
                <li>
                  The Company respects the Intellectual Property Rights of
                  others. All names, logos, marks, labels, trademarks,
                  copyrights or intellectual and proprietary rights on the
                  Platform belonging to any person (including User), entity or
                  third party are recognized as proprietary to the respective
                  owners. You are requested to send us a written notice/
                  intimation if you notice any act of infringement on the
                  Platform, which must include the following information:
                  <ul className="mt-4">
                    <li>
                      A clear identification of the copyrighted work allegedly
                      infringed;
                    </li>
                    <li>
                      A clear identification of the allegedly infringing
                      material on the Platform;
                    </li>
                    <li>
                      Your contact details: name, address, e-mail address and
                      phone number;
                    </li>
                    <li>
                      A statement that you believe, in good faith, that the use
                      of the copyrighted material allegedly infringed on the
                      Platform is not authorized by your agent or the law;
                    </li>
                    <li>
                      A statement that the information provided in the notice is
                      accurate, and under penalty of perjury, that the signatory
                      is authorized to act on behalf of the owner of an
                      exclusive copyright right that is allegedly infringed;
                    </li>
                    <li>
                      Your signature or a signature of your authorized agent.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                The aforesaid notices can be sent to the Company by email at{" "}
                <a href="mailto:support@topcrix11.com">
                  support@TopCrix11.com.
                </a>
              </p>
              <ul>
                <li>
                  On receiving such complaint, grievance or notice, the Company
                  has the right to investigate and/or take such action as the
                  Company may deem appropriate. The Company may reach out to you
                  to seek further clarification or assistance with the
                  investigation, or verify the statement s made in your
                  complaint, grievance or notice, and you acknowledge that your
                  timely assistance with the investigation would facilitate the
                  redressal of the same.
                </li>
              </ul>
              <p>
                The Grievance Officer identified above pursuant to the
                provisions of applicable laws including but not limited to the
                Information Technology Act, 2000 and the Consumer Protection
                Act, 2019, and the Rules enacted under those laws.
              </p>
              <p>
                The Company reserves the right to replace the Grievance
                Redressal Officer at its discretion through publication of the
                name and title of such replacement on the website, which
                replacement shall come into effect immediately upon publication.
              </p>

              <h3>
                23. Standard Terms and Conditions of the "Invite Friends"
                program.
              </h3>
              <p>
                The TopCrix11 Invite Friends Program lets you invite friends
                (“Inviter”) to the TopCrix11 app. In the event that you and your
                invited friend (“Invitee”) meet the criteria and complete all
                the steps specified in these terms, you will earn benefits as
                communicated to you on the app and your friend will receive{" "}
                <strong>
                  discounts worth Rs. 50 ( “Invitee Discount”). To participate
                  in the Program, please note our terms and conditions ("Terms")
                  in this respect, as they govern your participation in the
                  Program:
                </strong>
              </p>
              <ul>
                <li>
                  Eligibility – All users who: (i) have an account registered on
                  the "Platform"; and (ii) are eligible to participate in the
                  pay-to play game (as per the TopCrix11's terms and
                  conditions).
                </li>
                <li>
                  Participation in the program is voluntary. A user shall not
                  register or operate more than one user account with TopCrix11
                  and shall not participate in the Program with more than one
                  user account with TopCrix11.
                </li>
              </ul>
              <p>
                By participating in the Program, you agree to and accept these
                Terms:
              </p>
              <ul>
                <li>
                  Rewards will be received by the Inviter only when the Invitee
                  has joined a paid public contest. Simple registration or
                  depositing of cash on TopCrix11 by the Invitee will not result
                  in any rewards for the Inviter.
                </li>

                <li>
                  For the purpose of participation in the Program, you will be
                  provided with a unique link or code, which can be shared by
                  you with friends for the purpose of inviting such friends to
                  and create an account on the TopCrix11 Application. On
                  receiving the link or code from the Inviter, the Invitee may
                  either:
                  <ul>
                    <li>
                      Click on the link, consequent to which such Invitee will
                      be directed to a registration page and will be provided
                      the option to download and install the TopCrix11
                      Application on his/her device; and , register for a
                      TopCrix11 account via which he/she can enter the unique
                      code shared by the Inviter where prompted in the TopCrix11
                      Application
                    </li>
                  </ul>
                </li>
                <li>
                  The Inviter and the Invitee will be eligible to earn the
                  benefits (as communicated on the app) subject to (amongst
                  other terms specified in these Terms):
                  <ol>
                    <li>
                      The Invitee not being an existing user of TopCrix11;{" "}
                    </li>
                    <li>
                      The Inviter and Invitee being eligible to participate in
                      the pay-to play contests on TopCrix11;
                    </li>
                    <li>
                      The Invitee successfully registering for an account with
                      TopCrix11 through the unique link or by using the unique
                      code shared by the Inviter;
                    </li>
                    <li>
                      the Inviter and Invitee downloading and installing the
                      TopCrix11 Application as available for the Inviter’s and
                      Invitee’s respective mobile devices.
                    </li>
                  </ol>
                </li>
              </ul>
              <p>
                For the purposes of these Terms, an 'existing user of TopCrix11'
                shall mean a user who presently operates an account with
                TopCrix11 or operated an account with TopCrix11 at any point of
                time.
              </p>
              <p>
                An Invitee who is an existing user of TopCrix11 is not permitted
                to register a new account for the purpose of availing of the
                benefit as communicated on the app. TopCrix11 will determine in
                its sole discretion whether an Invitee is an existing user of
                TopCrix11 or not and take appropriate action.
              </p>
              <strong>
                The invited friend has to be at least 18 years old and not from
                one of the following states: Andhra Pradesh, Telangana, Sikkim,
                Odisha, Nagaland and Assam.
              </strong>
              <p>
                The verification process may require an Inviter/Invitee to
                submit personal information and documents identifying the
                Invitor/Invitee. The Inviter agrees to receive communication
                from TopCrix11 and to allow TopCrix11 to communicate with
                friends invited by you about the Inviter's participation in the
                Program. Any information collected in respect of the
                Inviter/Invitee as part of the Program or otherwise in the
                course of such person's use of the Platform shall be subject to
                TopCrix11's Privacy Policy (available here:
                https://www.topcrix11.designaim.in/privacy-policy for Indian users).
              </p>
              <p>
                The verification of an Inviter/Invitee shall be completed at the
                time of first paid/cash contest joined by the Inviter/Invitee or
                at the first time of adding cash to the Unutilised Account on
                the platform.
              </p>
              <p>
                Inviter can receive one of the following Rewards which shall be
                communicated to the Inviter via the in-app communications:
              </p>
              <p>
                In addition to the above, terms and conditions (if any) as
                applicable on each of the rewards shall be communicated to the
                Inviter via the in-app communications.The deposit of the rewards
                shall be subject to the Inviter’s/Invitee’s compliance with
                these Terms.
              </p>
              <h4>TopCrix11 reserves the right to:</h4>
              <ul>
                <li>Withhold the deposit of the rewards; and/or</li>
                <li>
                  Forfeit any deposited rewards to an Inviter/Invitee or any
                  prizes/winnings earned by the participant by using such
                  rewards; and/or
                </li>
                <li>
                  Deactivate the accounts of the Invitor/Invitee, in the event
                  that it determines or reasonably believes that such
                  Inviter/Invitee has violated these Terms or the terms and
                  conditions of the TopCrix11 fantasy games.
                </li>
              </ul>
              <p>
                TopCrix11 may, at its sole and absolute discretion, disqualify
                any Inviter/Invitee if such Invitor/Invitee engages in or it is
                found that such Invitor/Invitee has engaged in any illegal,
                unlawful or improper conduct (with regard to the Program or
                otherwise). The decision of TopCrix11 will be final and binding
                with regard to the Program, and the deposit of the Rewards and
                the Invitee Discount and no correspondence, objection,
                complaints, etc. will be entertained in this regard.
              </p>

              <p>
                This Program cannot be clubbed with any other
                promotions/programs that are running simultaneously by
                TopCrix11.
              </p>

              <p>
                The Terms and Conditions, as applicable to the TopCrix11's
                fantasy games and services, will apply to and govern the
                Program.
              </p>

              <p>
                TopCrix11 does not make any commitment, express or implied, to
                respond to any feedback, suggestion and, or, queries of the
                participants (Invitor/Invitee) of the Program.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
